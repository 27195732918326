import React from "react";
import renderHTML from "react-render-html";

import "./boardContents.css";

export default class BoardContents extends React.Component {
  constructor(props) {
    super(props);
    let pathname = this.props.location.pathname;
    let postNum = "";
    if (pathname) {
      postNum = pathname.replace(/\D/gi, "");
      this.props.read(postNum);
    }
    this.state = { postNum };
  }

  onRemovePost() {
    this.props.remove(this.state.postNum);
    window.history.back();
  }
  render() {
    return Object.values(this.props.post).length ? (
      <div className="container">
        <div style={{ width: "100%", height: "20px" }} />
        <table className="table">
          <tbody>
            <tr>
              <th
                className="text-center board-contents-title-text col-sm-6 board-contents-title-th"
                colSpan="2"
              >
                {this.props.post.title}
              </th>
            </tr>
            <tr>
              <th className="text-center board-contents-head-text board-contents-head-th-3">
                작성일
              </th>
              <th className="text-center board-contents-head-text board-contents-head-th-4">
                {this.props.post.date}
              </th>
            </tr>
            <tr>
              <th className="board-contents-textarea" colSpan="2">
                {this.props.post.img && (
                  <div className="w-100 text-center mb-5">
                    <img
                      className="img-fluid board-contents-image-forIE"
                      src={`/images/${this.props.post.img}`}
                      alt="board-contents"
                    />
                  </div>
                )}
                <div>{renderHTML(this.props.post.content)}</div>
              </th>
            </tr>
            <tr className="border-0">
              <td colSpan="4" className="text-right border-0">
                {this.props.loginType === "admin" && (
                  <button
                    type="button"
                    className="board-contents-button"
                    onClick={() => this.onRemovePost()}
                  >
                    삭제
                  </button>
                )}
                <button
                  onClick={() => window.history.back()}
                  type="button"
                  className="board-contents-button"
                >
                  목록
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          className="btn-group float-right my-3"
          role="group"
          aria-label="Basic example"
        />
      </div>
    ) : (
      <div className="container">
        <table className="table table-striped table-hover">
          <tbody>
            <div className="container text-center">
              <div className="jumbotron bg-transparent login-text-container text-center">
                <div className="login-text-title py-3">잘못된 접근입니다.</div>
                <div className="login-text-context">
                  <button
                    className="board-contents-button"
                    onClick={() => window.history.back()}
                  >
                    돌아가기
                  </button>
                </div>
              </div>
            </div>
          </tbody>
        </table>
      </div>
    );
  }
}

BoardContents.defaultProps = {
  post: {
    no: 0,
    title: "",
    writer: "관리자",
    email: "",
    phoneNumber: "",
    content: "",
    date: "",
    hits: 0,
  },
};
