import React from "react";
import "./FAB.css";

import firstImage from "../../resources/fabicon/mobile-1.png";
import secondImage from "../../resources/fabicon/mobile-2.png";
import thirdImage from "../../resources/fabicon/mobile-3.png";

const MobileFab = () => {
  return (
    <div className="mobile-fab-container position-fixed">
      <div>
        <button
          className="mobile-fab-button my-1 p-0"
          onClick={() =>
            window.open(
              "https://m.place.naver.com/hospital/1801024622/review/visitor"
            )
          }
        >
          <img className="img-fluid" src={firstImage} alt="mobile-fab-review" />
        </button>
      </div>
      <div>
        <button
          className="mobile-fab-button my-1 p-0"
          onClick={() => window.open("tel:0537951212")}
        >
          <img className="img-fluid" src={secondImage} alt="mobile-fab-tel" />
        </button>
      </div>
      <div>
        <button
          id="mobile-fab-kakao-button"
          className="mobile-fab-button my-1 p-0"
          onClick={() => window.open("http://pf.kakao.com/_PuvVj/chat")}
        >
          <img className="img-fluid" src={thirdImage} alt="mobile-fab-kakao" />
        </button>
      </div>
    </div>
  );
};

export default MobileFab;
