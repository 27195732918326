import BoardContents from "../components/board/boardContents";
import { connect } from "react-redux";
import * as actions from "../actions/Board";

const mapStateToProps = (state) => {
  return {
    post: state.board.post,
    loginType: state.auth.loginType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  read: (postNum) => dispatch(actions.readContent(postNum)),
  remove: (postNum) => dispatch(actions.removeContent(postNum)),
  submitAnswer: (num, answer) => dispatch(actions.submitAnswer(num, answer)),
  removeAnswer: (num) => dispatch(actions.removeAnswer(num)),
});

const BoardContentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BoardContents);

export default BoardContentsContainer;
