import Admin from "../components/login/admin";
import { connect } from "react-redux";
import * as actions from "../actions/Auth";

const mapStateToProps = (state) => {
  return {
    loginType: state.auth.loginType,
    username: state.auth.username,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginRequest: (input) => dispatch(actions.adminLogin(input)),
  loginCheck: () => dispatch(actions.loginCheck()),
});

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Admin);

export default LoginContainer;
