import React from "react";
import BoardController from "./boardController";
import BoardIndex from "./boardIndex";
import Breadcrumb from "../../components/navbar/Breadcrumb";

import newImage from "../../resources/board/new.png";
import reImage from "../../resources/board/re.png";
import noreImage from "../../resources/board/nore.png";

import counselImage from "../../resources/board/counsel.png";
import mobileCounselImage from "../../resources/board/mobile_counsel.png";

import "./board.css";
const TWO_DAYS = 1000 * 60 * 60 * 24 * 2;

export default class Board extends React.Component {
  constructor(props) {
    super(props);
    this.path = this.props.location.pathname;
    this.parsedContents = [
      <tr>
        <td className="text-center" colSpan="6">
          표시할 자료가 없습니다.
        </td>
      </tr>,
    ];
    if (Array.isArray(this.props.contents)) {
      if (this.props.contents.length) {
        this.parsedContents = this.makeContents(this.props.contents);
      }
    }
    this.props.fetch();
  }

  componentWillReceiveProps(nextProps) {
    if (!Array.isArray(nextProps.contents)) {
      this.parsedContents = [
        <tr>
          <td className="text-center" colSpan="6">
            표시할 자료가 없습니다.
          </td>
        </tr>,
      ];
      return;
    }
    if (nextProps.contents.length) {
      this.parsedContents = this.makeContents(nextProps.contents);
    } else {
      this.parsedContents = [
        <tr>
          <td className="text-center" colSpan="6">
            표시할 자료가 없습니다.
          </td>
        </tr>,
      ];
    }
  }

  makeContents(contentsArray) {
    return contentsArray.map((value, index) => {
      const key = `board-${index}`;
      const endPoint = `${this.path}/${value.no}`;
      var nowDate = new Date();
      var date = new Date(value.date);

      return (
        <tr key={key}>
          <td className="text-center d-md-table-cell d-none">{value.no}</td>
          <td className="board-title-td">
            {nowDate.getTime() - date.getTime() < TWO_DAYS ? (
              <img
                className="img-fluid board-reply-img mr-2"
                src={newImage}
                alt="new"
              />
            ) : (
              ""
            )}
            <a className="board-title-td" href={endPoint}>
              {value.title}
            </a>
          </td>
          <td className="text-center">{value.writer}</td>
          <td className="text-center d-md-table-cell d-none">
            {value.isAnswered ? (
              <img
                className="img-fluid board-reply-img"
                src={reImage}
                alt="re"
              />
            ) : (
              <img
                className="img-fluid board-reply-img"
                src={noreImage}
                alt="nore"
              />
            )}
          </td>
          <td className="text-center d-md-table-cell d-none">
            {value.date.match(/\d{0,4}-\d{0,2}-\d{0,2}/)[0]}
          </td>
          <td className="text-center d-md-table-cell d-none">{value.hits}</td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div>
        <Breadcrumb topMenu="커뮤니티" keyName={"community"} index={1} />
        <div style={{ width: "100%", height: "30px" }} />
        <div className="container">
          <div
            className="container  p-0"
            style={{ backgroundColor: "#f7f7f7" }}
          >
            <div className="col-sm-12 p-0 d-md-inline-block d-none">
              <img
                className="img-fluid"
                src={counselImage}
                alt="counsel-background"
              />
            </div>
            <div className="col-sm-12 p-0 d-md-none d-inline-block">
              <img
                className="img-fluid"
                src={mobileCounselImage}
                alt="counsel-background"
              />
            </div>
          </div>
          <div style={{ width: "100%", height: "30px" }} />
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="text-center d-md-table-cell d-none board-th-1">
                  번호
                </th>
                <th className="text-center board-th-2  px-0">제목</th>
                <th className="text-center board-th-3">작성자</th>
                <th className="text-center board-th-4 d-md-table-cell d-none">
                  답변
                </th>
                <th className="text-center board-th-5 d-md-table-cell d-none">
                  등록일
                </th>
                <th className="text-center board-th-6 d-md-table-cell d-none">
                  조회수
                </th>
              </tr>
            </thead>
            <tbody>{this.parsedContents}</tbody>
          </table>
          <BoardIndex
            current={this.props.currentPage}
            length={this.props.pageLength}
            otherPage={(index) => {
              this.props.otherPage(index);
              this.props.fetch();
            }}
          />
          <BoardController
            my={this.props.my}
            board={this.path}
            search={false}
            setParams={() => this.props.searchMyContents()}
            getMyContents={() => this.props.fetch()}
          />
        </div>
      </div>
    );
  }
}

Board.defaultProps = {
  contents: [],
  pageLength: 1,
  currentPage: 1,
  searchMyContents: () => {},
};
