import React from "react";

export default class Redirect extends React.Component {
  constructor(props) {
    super(props);
    alert("준비중인 메뉴입니다.");
    window.history.back();
  }
  render() {
    return <div>돌아가기</div>;
  }
}
