import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/Auth";

const mapStateToProps = (state) => {
  return {
    loginType: state.auth.loginType,
    username: state.auth.username,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginCheck: () => dispatch(actions.loginCheck()),
});

class NavbarLoginButton extends React.Component {
  constructor(props) {
    super(props);
    this.props.loginCheck();
    if (this.props.loginType) {
      this.state = { loginType: this.props.loginType };
    } else {
      this.state = { loginType: "" };
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ loginType: nextProps.loginType });
  }

  render() {
    return (
      <div className="w-50 text-center navbar-login-button-container">
        <div className="nav-item navbar-login-button">
          {this.state.loginType ? (
            <a href="/login/logout">LOGOUT</a>
          ) : (
            <a href="/signin">LOGIN</a>
          )}
        </div>
      </div>
    );
  }
}

const NavbarLoginButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarLoginButton);

export default NavbarLoginButtonContainer;
