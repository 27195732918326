import React from "react";
import PopupUpload from "./PopupUpload";

export default class PopupConfig extends React.Component {
  constructor(props) {
    super(props);
    this.props.fetchPopup();
    this.popupList = [
      <li className="list-group-item"> 등록된 팝업이 없습니다.</li>,
    ];
    if (this.props.contents.length) {
      this.popupList = this.makePopupList(this.props.contents);
    }
  }

  makePopupList(contentsArray) {
    if (!Array.isArray(contentsArray)) {
      return [<li className="list-group-item"> 등록된 팝업이 없습니다.</li>];
    }
    return contentsArray.map((eachContents, index) => {
      return (
        <li className="list-group-item">
          <div>
            게시 종료일: {eachContents.expire}
            <button
              className="btn btn-primary float-right"
              onClick={() => this.props.removePopup(eachContents.no)}
            >
              삭제
            </button>
          </div>
          <div className="">
            <img
              className="img-fluid"
              src={`/popups/${eachContents.img}`}
              alt="popupconfig"
            />
          </div>
        </li>
      );
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.contents) {
      this.popupList = this.makePopupList(nextProps.contents);
    }
  }

  render() {
    return (
      <div className="container">
        {this.props.loginType === "admin" && (
          <>
            <h3>팝업 관리</h3>
            <div>
              <h5>등록된 팝업 목록</h5>
              <div>
                <ul className="list-group">{this.popupList}</ul>
              </div>
            </div>

            <div>
              <h5>팝업 업로드</h5>
              <PopupUpload
                onSubmit={(input) => {
                  this.props.uploadPopup(input);
                }}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}
