import React from "react";
import BoardController from "./boardController";
import BoardIndex from "./boardIndex";
import Breadcrumb from "../../components/navbar/Breadcrumb";

import reviewImage from "../../resources/board/review.png";
import mobileReviewImage from "../../resources/board/mobile_review.png";

import "./board.css";

export default class Board extends React.Component {
  constructor(props) {
    super(props);
    this.props.loginCheck();
    this.path = this.props.location.pathname;
    if (this.props.loginType) {
      this.state = { loginType: this.props.loginType };
    } else {
      this.state = { loginType: "" };
    }
    this.parsedContents = [
      <div className="">
        <div
          className="text-center d-table text-center mx-auto"
          style={{ height: "40vh", position: "relative" }}
        >
          <span
            className="d-table-cell"
            style={{
              verticalAlign: "middle",
              textAlign: "center",
              fontSize: "1.2rem",
            }}
          >
            표시할 자료가 없습니다.
          </span>
        </div>
      </div>,
    ];
    this.parsedContents = this.makeContents(this.props.contents);
    this.props.fetch();
  }

  makeContents(contentsArray) {
    return contentsArray.map((value, index) => {
      const key = `board-${index}`;
      const endPoint = `${this.path}/${value.no}`;
      return (
        <div className="p-0 d-inline-block review-contents-container" key={key}>
          <div className="p-0 border">
            <ul className="list-group list-group-flush">
              <li className="list-group-item text-center review-contents-image-container">
                <a href={endPoint}>
                  <img
                    className="img-fluid review-contents-image"
                    src={`/thumbnails/${value.img}`}
                    alt="review-context"
                  />
                </a>
              </li>
              <li className="list-group-item review-contents-title-container">
                <a href={endPoint}>{value.title}</a>
              </li>
              <li className="list-group-item">
                {value.date.match(/\d{0,4}-\d{0,2}-\d{0,2}/)[0]}
              </li>
            </ul>
          </div>
        </div>
      );
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.contents) {
      this.parsedContents = this.makeContents(nextProps.contents);
    }
    if (nextProps.loginType) {
      this.setState({ loginType: nextProps.loginType });
    }
  }

  render() {
    return (
      <div>
        <Breadcrumb topMenu="커뮤니티" keyName={"community"} index={0} />
        <div style={{ width: "100%", height: "30px" }} />
        <div className="container  p-0" style={{ backgroundColor: "#f7f7f7" }}>
          <div className="col-sm-12 p-0 d-md-inline-block d-none">
            <img
              className="img-fluid"
              src={reviewImage}
              alt="review-background"
            />
          </div>
          <div className="col-sm-12 p-0 d-md-none d-inline-block">
            <img
              className="img-fluid"
              src={mobileReviewImage}
              alt="review-bacakground-mob"
            />
          </div>
        </div>
        <div style={{ width: "100%", height: "30px" }} />
        <div className="container p-0">
          <div>{this.parsedContents}</div>
          <div style={{ width: "100%", height: "30px" }} />
          <BoardIndex
            current={this.props.currentPage}
            length={this.props.pageLength}
            otherPage={(index) => {
              this.props.otherPage(index);
              this.props.fetch();
            }}
          />
          <BoardController
            board={this.path}
            search={true}
            admin={this.state.loginType === "admin"}
            ref="boardController"
            onSearchSubmit={() => {
              if (!this.refs.boardController.state.keyword) {
                alert("검색할 내용을 입력해주세요.");
                return;
              }
              this.props.onSearchSubmit(
                this.refs.boardController.state.keyword
              );
              this.props.fetch();
            }}
          />
        </div>
      </div>
    );
  }
}

Board.defaultProps = {
  contents: [],
  pageLength: 1,
  currentPage: 1,
};
