import React from "react";
import prologueImage from "../resources/prologue/prologue.png";

export default class Prologue extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="text-center">
        <div className="col-xl-7 d-inline-block">
          <img className="img-fluid" alt="내용1" src={prologueImage} />
        </div>
        {/* <Card
        image={require('../resources/prologue/1.png')}
        title="잘 모르는 신경과 이야기"
        subtitleFirst='두통이나 통증, 다양한 신경계통 질환을 치료하는 곳은?'
        subtitleSecond='신경외과가 아니라 신경과입니다!'
        ></Card>
        
        <div className="w-100 my-4"></div>
          <div className="row mx-4">
            <LeftCard></LeftCard>
            <RightCard></RightCard>
          </div>
          <div className="w-100 my-4"></div>
          <NewsPaper></NewsPaper>
          <div className="w-100 my-4"></div>
          <NewsWithBackground/>
          <div className="w-100 my-4"></div>
          <Circle></Circle>
          <div className="w-100 my-4"></div>
          <Specificity/> */}
      </div>
    );
  }
}
