import React from "react";
import "./Carousel.css";
import Item1 from "./items/item-1";
import Item2 from "./items/item-2";
import Item3 from "./items/item-3";
import MobileItem from "./items/mobile-item";

import Mobile1 from "../../resources/carousel/mobile-1.png";
import Mobile2 from "../../resources/carousel/mobile-2.png";
import Mobile3 from "../../resources/carousel/mobile-3.png";

const Carousel = () => {
  return (
    <div className="col-sm-12 carousel-backgound p-0">
      <div className="carousel-container row">
        <div
          className="carousel slide mx-auto col-sm-12"
          id="carouselIndicators"
          data-ride="carousel"
        >
          {window.innerWidth > 768 ? (
            <div className="carousel-inner carousel-desktop">
              <div className="carousel-item active">
                <Item1 />
              </div>
              <div className="carousel-item">
                <Item2 />
              </div>
              <div className="carousel-item">
                <Item3 />
              </div>
            </div>
          ) : (
            <div className="carousel-inner carousel-mobile">
              <div className="carousel-item mobile-item active">
                <MobileItem link="/introduce/1" image={Mobile1} />
              </div>
              <div className="carousel-item mobile-item">
                <MobileItem link="/clinicA/1" image={Mobile2} />
              </div>
              <div className="carousel-item mobile-item">
                <MobileItem link="/clinicB/1" image={Mobile3} />
              </div>
            </div>
          )}
          <a
            className="carousel-control-prev p-3"
            href="#carouselIndicators"
            role="button"
            data-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next p-3"
            href="#carouselIndicators"
            role="button"
            data-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </a>
          {/* <ol className="carousel-indicators">
          <li data-target="#carouselIndicators" data-slide-to="0" className="active" style={{width:50, height:50, borderRadius: 50}}></li>
          <li data-target="#carouselIndicators" data-slide-to="1"></li>
          <li data-target="#carouselIndicators" data-slide-to="2"></li>
        </ol> */}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
