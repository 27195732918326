export const NEED_LOGIN = "NEED_LOGIN";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGIN_ALREADY = "LOGIN_ALREADY";

// 상담 게시판

export const COUNSEL_FETCH_REQUEST = "COUNSEL_FETCH_REQUEST";
export const COUNSEL_FETCH_SUCCESS = "COUNSEL_FETCH_SUCCESS";
export const COUNSEL_FETCH_FAILURE = "COUNSEL_FETCH_FAILURE";

export const COUNSEL_READ_POST_REQUEST = "COUNSEL_READ_POST_REQUEST";
export const COUNSEL_READ_POST_SUCCESS = "COUNSEL_READ_POST_SUCCESS";
export const COUNSEL_READ_POST_FAILURE = "COUNSEL_READ_POST_FAILURE";

export const COUNSEL_WRITE_POST_REQUEST = "COUNSEL_WRITE_POST_REQUEST";
export const COUNSEL_WRITE_POST_SUCCESS = "COUNSEL_WRITE_POST_SUCCESS";
export const COUNSEL_WRITE_POST_FAILURE = "COUNSEL_WRITE_POST_FAILURE";

export const COUNSEL_WRITE_ANSWER_REQUEST = "COUNSEL_WRITE_ANSWER_REQUEST";
export const COUNSEL_WRITE_ANSWER_SUCCESS = "COUNSEL_WRITE_ANSWER_SUCCESS";
export const COUNSEL_WRITE_ANSWER_FAILURE = "COUNSEL_WRITE_ANSWER_FAILURE";

export const COUNSEL_EDIT_POST_REQUEST = "COUNSEL_EDIT_POST_REQUEST";
export const COUNSEL_EDIT_POST_SUCCESS = "COUNSEL_EDIT_POST_SUCCESS";
export const COUNSEL_EDIT_POST_FAILURE = "COUNSEL_EDIT_POST_FAILURE";

export const COUNSEL_REMOVE_POST_REQUEST = "COUNSEL_REMOVE_POST_REQUEST";
export const COUNSEL_REMOVE_POST_SUCCESS = "COUNSEL_REMOVE_POST_SUCCESS";
export const COUNSEL_REMOVE_POST_FAILURE = "COUNSEL_REMOVE_POST_FAILURE";

export const COUNSEL_PAGE_MOVE = "COUNSEL_PAGE_MOVE";
export const COUNSEL_MODE_MY = "COUNSEL_MODE_MY";
// 후기 게시판

export const REVIEW_FETCH_REQUEST = "REVIEW_FETCH_REQUEST";
export const REVIEW_FETCH_SUCCESS = "REVIEW_FETCH_SUCCESS";
export const REVIEW_FETCH_FAILURE = "REVIEW_FETCH_FAILURE";

export const REVIEW_PAGE_MOVE = "REVIEW_PAGE_MOVE";
export const REVIEW_MODE_SEARCH = "REVIEW_MODE_SEARCH";

export const REVIEW_READ_POST_REQUEST = "REVIEW_READ_POST_REQUEST";
export const REVIEW_READ_POST_SUCCESS = "REVIEW_READ_POST_SUCCESS";
export const REVIEW_READ_POST_FAILURE = "REVIEW_READ_POST_FAILURE";

export const REVIEW_WRITE_POST_REQUEST = "REVIEW_WRITE_POST_REQUEST";
export const REVIEW_WRITE_POST_SUCCESS = "REVIEW_WRITE_POST_SUCCESS";
export const REVIEW_WRITE_POST_FAILURE = "REVIEW_WRITE_POST_FAILURE";

export const REVIEW_EDIT_POST_REQUEST = "REVIEW_EDIT_POST_REQUEST";
export const REVIEW_EDIT_POST_SUCCESS = "REVIEW_EDIT_POST_SUCCESS";
export const REVIEW_EDIT_POST_FAILURE = "REVIEW_EDIT_POST_FAILURE";

export const REVIEW_REMOVE_POST_REQUEST = "REVIEW_REMOVE_POST_REQUEST";
export const REVIEW_REMOVE_POST_SUCCESS = "REVIEW_REMOVE_POST_SUCCESS";
export const REVIEW_REMOVE_POST_FAILURE = "REVIEW_REMOVE_POST_FAILURE";

// 빠른 상담
export const QUICK_COUNSEL_REQUEST = "QUICK_COUNSEL_REQUEST";
export const QUICK_COUNSEL_SUCCESS = "QUICK_COUNSEL_SUCCESS";
export const QUICK_COUNSEL_FAILURE = "QUICK_COUNSEL_FAILURE";

//팝업
export const POP_UP_FETCH_REQUEST = "POP_UP_FETCH_REQUEST";
export const POP_UP_FETCH_SUCCESS = "POP_UP_FETCH_SUCCESS";
export const POP_UP_FETCH_FAILURE = "POP_UP_FETCH_FAILURE";

export const POP_UP_UPLOAD_REQUEST = "POP_UP_UPLOAD_REQUEST";
export const POP_UP_UPLOAD_SUCCESS = "POP_UP_UPLOAD_SUCCESS";
export const POP_UP_UPLOAD_FAILURE = "POP_UP_UPLOAD_FAILURE";

export const POP_UP_REMOVE_REQUEST = "POP_UP_REMOVE_REQUEST";
export const POP_UP_REMOVE_SUCCESS = "POP_UP_REMOVE_SUCCESS";
export const POP_UP_REMOVE_FAILURE = "POP_UP_REMOVE_FAILURE";
