import React from "react";
import FabItem from "./FABItem";
import "./FAB.css";

import upImage from "../../resources/fabicon/up.png";
import downImage from "../../resources/fabicon/down.png";
import firstImage from "../../resources/fabicon/1.png";
import secondImage from "../../resources/fabicon/2.png";
import thirdImage from "../../resources/fabicon/3.png";
import forthImage from "../../resources/fabicon/4.png";
import fifthImage from "../../resources/fabicon/5.png";
import sixthImage from "../../resources/insta.png";
import seventhImage from "../../resources/facebook.png";
import eightImage from "../../resources/blog.png";
import naverImage from "../../resources/naver.png";

export default class FAB extends React.Component {
  constructor(props) {
    super(props);
    this.state = { toggle: true };
  }
  render() {
    return (
      <div
        className="position-fixed fab-reponsive-container"
        style={{ top: "10%", right: 0 }}
      >
        <div className="btn-group-vertical">
          <button
            onClick={() => {
              let toggle = !this.state.toggle;
              this.setState({ toggle });
            }}
            className="fab-top-container text-center py-2"
          >
            QUICK MENU
            {this.state.toggle ? (
              <img
                alt="up"
                style={{ width: "19px", height: "15px" }}
                className="img-fluid mb-1 mx-1"
                src={upImage}
              />
            ) : (
              <img
                alt="down"
                style={{ width: "19px", height: "15px" }}
                className="img-fluid mb-1 mx-1"
                src={downImage}
              />
            )}
          </button>
          {this.state.toggle && (
            <div>
              <FabItem
                image={firstImage}
                title="오시는길"
                backgroundColor="#309683"
                buttonAction={() => {
                  window.open("/introduce/4", "_self");
                }}
              />
              <FabItem
                image={secondImage}
                title="진료시간안내"
                backgroundColor="#359ccb"
                buttonAction={() => {
                  window.open("/introduce/4", "_self");
                }}
              />
              <FabItem
                image={thirdImage}
                title="빠른상담"
                backgroundColor="#479e39"
                buttonAction={() => {
                  window.location.hash = "#footer-quick";
                }}
              />
              <FabItem
                image={forthImage}
                title="진료후기"
                backgroundColor="#1d7ab3"
                buttonAction={() => {
                  window.open("/board/review", "_self");
                }}
              />
              <FabItem
                image={fifthImage}
                title="카톡상담"
                backgroundColor="#383d52"
                buttonAction={() => {
                  window.open("http://pf.kakao.com/_PuvVj/chat");
                }}
              />
              <FabItem
                image={sixthImage}
                title="인스타그램"
                backgroundColor="#075289"
                buttonAction={() => {
                  window.open("https://instagram.com/neurocheong");
                }}
              />
              <FabItem
                image={seventhImage}
                title="페이스북"
                backgroundColor="#212B5D"
                buttonAction={() => {
                  window.open("https://www.facebook.com/neurocheong");
                }}
              />
              <FabItem
                image={eightImage}
                title="블로그"
                backgroundColor="#312250"
                buttonAction={() => {
                  window.open("https://blog.naver.com/neurocheong1");
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
