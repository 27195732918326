import * as types from "../actions/ActionTypes";

const initialState = {
  contents: [],
  currentPage: 1,
  pageLength: 1,
  postNumber: 0,
  keyword: "",
  post: {},
};

function board(state = initialState, action) {
  switch (action.type) {
    case types.REVIEW_FETCH_REQUEST:
      return { ...state };
    case types.REVIEW_FETCH_SUCCESS:
      return Object.assign({}, state, {
        contents: action.contents,
        pageLength: action.pageLength,
      });
    case types.REVIEW_PAGE_MOVE:
      return Object.assign({}, state, {
        currentPage: action.currentPage,
      });
    case types.REVIEW_MODE_SEARCH:
      return Object.assign({}, state, {
        currentPage: action.currentPage,
        keyword: action.keyword,
      });
    case types.REVIEW_READ_POST_SUCCESS:
      return Object.assign({}, state, {
        post: action.post,
      });
    default:
      return {
        ...state,
      };
  }
}

export default board;
