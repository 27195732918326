import React from "react";
import Breadcrumb from "../components/navbar/Breadcrumb";

import ClinicB1 from "../resources/clinicB/1.png";
import ClinicB2 from "../resources/clinicB/2.png";
import ClinicB3 from "../resources/clinicB/3.png";
import ClinicB4 from "../resources/clinicB/4.png";

const images = [ClinicB1, ClinicB2, ClinicB3, ClinicB4];

export default class ClinicB extends React.Component {
  constructor(props) {
    super(props);
    this.image = images[this.props.match.params.path - 1];
    if (!this.image) {
      this.image = images[0];
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Breadcrumb
          topMenu="통증클리닉"
          keyName={"B"}
          index={this.props.match.params.path - 1}
        />
        <div className="text-center">
          <div className="col-xl-5 d-inline-block">
            <div className="w-100 my-4" style={{ height: "20px" }} />
            <div>
              <img className="img-fluid" src={this.image} alt="1" />
            </div>
            <div className="w-100 my-4" style={{ height: "20px" }} />
          </div>
        </div>
      </div>
    );
  }
}
