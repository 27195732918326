import React from "react";
import "./homeCard.css";
import HomeCardItem from "./homeCardItem";

import A01 from "../../resources/homeCard/A01.png";
import A02 from "../../resources/homeCard/A02.png";
import A03 from "../../resources/homeCard/A03.png";

import B01 from "../../resources/homeCard/B01.png";
import B02 from "../../resources/homeCard/B02.png";
import B03 from "../../resources/homeCard/B03.png";

import C01 from "../../resources/homeCard/C01.png";
import C02 from "../../resources/homeCard/C02.png";
import C03 from "../../resources/homeCard/C03.png";

const HomeCard = () => {
  return (
    <div className="homecard-container row">
      <HomeCardItem
        id="homecardCarousel1"
        image1={A01}
        image2={A02}
        image3={A03}
        link={["/introduce/2", "/introduce/3", "/clinicB/3"]}
      />
      <HomeCardItem
        id="homecardCarousel2"
        image1={B01}
        image2={B02}
        image3={B03}
        link={["/clinicA/11", "/clinicB/1", "/clinicB/3"]}
      />
      <HomeCardItem
        id="homecardCarousel3"
        image1={C01}
        image2={C02}
        image3={C03}
        link={["#", "#", "#"]}
      />
    </div>
  );
};

export default HomeCard;
