import React from "react";

const makeButton = (index, onClick) => (
  <button
    onClick={() => onClick(index)}
    type="button"
    className="board-index-button"
    key={"boardbutton" + index}
  >
    {index}
  </button>
);

const makeActiveButton = (index) => (
  <button
    disabled
    type="button"
    className="board-index-button bg-dark"
    key={"boardbutton" + index}
  >
    {index}
  </button>
);

const BoardIndex = ({ otherPage, current, length }) => {
  let indexComponent = [];
  if (length < 5) {
    for (let i = 1; i <= length; i++) {
      if (current === i) {
        indexComponent.push(makeActiveButton(i));
      } else {
        indexComponent.push(makeButton(i, (index) => otherPage(index)));
      }
    }
  }

  if (length > 5) {
    if (current === 1) {
      for (let i = 1; i <= 5; i++) {
        if (current === i) {
          indexComponent.push(makeActiveButton(i));
        } else {
          indexComponent.push(makeButton(i, (index) => otherPage(index)));
        }
      }
    } else if (current === 2) {
      for (let i = 1; i <= 5; i++) {
        if (current === i) {
          indexComponent.push(makeActiveButton(i));
        } else {
          indexComponent.push(makeButton(i, (index) => otherPage(index)));
        }
      }
    } else if (length <= current + 2) {
      for (let i = current - 2; i <= length; i++) {
        if (current === i) {
          indexComponent.push(makeActiveButton(i));
        } else {
          indexComponent.push(makeButton(i, (index) => otherPage(index)));
        }
      }
    } else if (current >= 3) {
      for (let i = current - 2; i <= current + 2; i++) {
        if (current === i) {
          indexComponent.push(makeActiveButton(i));
        } else {
          indexComponent.push(makeButton(i, (index) => otherPage(index)));
        }
      }
    }
  }

  return <div className="text-center">{indexComponent}</div>;
};

export default BoardIndex;
