import React from "react";
import renderHTML from "react-render-html";
import "./boardContents.css";

export default class BoardContents extends React.Component {
  constructor(props) {
    super(props);
    let pathname = this.props.location.pathname;
    let postNum = "";
    if (pathname) {
      postNum = pathname.replace(/\D/gi, "");
      this.props.read(postNum);
    }
    this.state = { postNum, writeAnswer: false, comment: "" };
  }

  onBack() {
    window.history.back();
  }

  onRemovePost() {
    this.props.remove(this.state.postNum);
    window.history.back();
  }

  submitAnswer() {
    if (!this.validateAnswer()) {
      return false;
    }
    this.props.submitAnswer(
      this.props.post.no,
      this.parseSubmitContents(this.state.comment)
    );
  }

  validateAnswer() {
    let answer = this.state.comment;

    if (answer.length < 10) {
      alert("10자 이상으로 입력하세요.");
      return false;
    }
    if (answer.length > 2000) {
      alert("2000자 이하로 입력하세요.");
      return false;
    }
    return true;
  }

  parseSubmitContents(content) {
    return `<p>
    ${content.replace(/\n{1,}/gi, "<br/>")}
    </p>`;
  }

  render() {
    return Object.values(this.props.post).length ? (
      <div className="container">
        <div style={{ width: "100%", height: "20px" }} />
        <table className="table">
          <tbody>
            <tr className="">
              <th
                className="text-center board-contents-title-text col-sm-6 board-contents-title-th"
                colSpan="4"
              >
                {this.props.post.title}
              </th>
            </tr>
            <tr className="">
              <th className="text-center board-contents-head-text board-contents-head-th-1">
                작성자
              </th>
              <th className="text-center board-contents-head-text board-contents-head-th-2">
                {this.props.post.writer}
              </th>
              <th className="text-center board-contents-head-text board-contents-head-th-3">
                작성일
              </th>
              <th className="text-center board-contents-head-text board-contents-head-th-4">
                {this.props.post.date}
              </th>
            </tr>
            <tr className="">
              <th className="text-center board-contents-head-text board-contents-head-th-1">
                EMAIL
              </th>
              <th className="text-center board-contents-head-text board-contents-head-th-2">
                {this.props.post.email}
              </th>
              <th className="text-center board-contents-head-text board-contents-head-th-3">
                전화번호
              </th>
              <th className="text-center board-contents-head-text board-contents-head-th-4">
                {this.props.post.phoneNumber}
              </th>
            </tr>
            <tr>
              <th className="board-contents-textarea" colSpan="4">
                {renderHTML(this.props.post.content)}
              </th>
            </tr>
            {this.props.post.answer && (
              <tr>
                <th className="board-contents-textarea" colSpan="4">
                  답변입니다.
                  <br />
                  <br />
                  <br />
                  {renderHTML(this.props.post.answer)}
                </th>
              </tr>
            )}
            {this.props.post.answer && this.props.loginType === "admin" && (
              <tr>
                <td className="board-contents-textarea" colSpan="4">
                  <button
                    onClick={() => this.props.removeAnswer(this.props.post.no)}
                    type="button"
                    className="board-contents-button"
                  >
                    답글 삭제하기
                  </button>
                </td>
              </tr>
            )}
            {this.state.writeAnswer && (
              <tr>
                <td className="board-contents-textarea">
                  답변내용
                  <br />
                  {this.state.comment.length}
                  /2000
                </td>
                <td className="board-contents-input" colSpan="3">
                  <textarea
                    onChange={(e) => this.setState({ comment: e.target.value })}
                    type="textarea"
                    className="form-control board-contents-input-text"
                    rows="10"
                    maxLength="2000"
                    placeholder="10자 이상 2000자 이하로 작성하여 주십시오."
                  />
                </td>
              </tr>
            )}
            {this.state.writeAnswer && (
              <tr>
                <td colSpan="4" className="text-right">
                  <button
                    className="board-contents-button"
                    onClick={() => this.submitAnswer()}
                  >
                    답글등록
                  </button>
                </td>
              </tr>
            )}
            <tr className="border-0">
              <td colSpan="4" className="text-right border-0">
                {this.props.loginType === "admin" && (
                  <button
                    onClick={() => {
                      if (this.props.post.answer) {
                        alert("이미 답글이 등록되어있는 글 입니다.");
                        return;
                      }
                      this.setState({ writeAnswer: true });
                    }}
                    type="button"
                    className="board-contents-button"
                  >
                    답글달기
                  </button>
                )}
                <button
                  onClick={() => window.history.back()}
                  type="button"
                  className="board-contents-button"
                >
                  목록
                </button>
                <button
                  type="button"
                  className="board-contents-button"
                  onClick={() => this.onRemovePost()}
                >
                  삭제
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ) : (
      <div className="container">
        <table className="table table-striped table-hover">
          <tbody>
            <div className="container text-center">
              <div className="jumbotron bg-transparent login-text-container text-center">
                <div className="board-contents-back-title py-3">
                  상담 글은 작성자 본인 이외 열람이 제한됩니다.
                </div>
                <div className="login-text-context">
                  <button
                    className="board-contents-button"
                    onClick={() => this.onBack()}
                  >
                    돌아가기
                  </button>
                </div>
              </div>
            </div>
          </tbody>
        </table>
      </div>
    );
  }
}

BoardContents.defaultProps = {
  post: {
    no: 0,
    title: "",
    writer: "관리자",
    email: "",
    phoneNumber: "",
    content: "",
    date: "",
    hits: 0,
  },
};
