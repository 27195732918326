import React from "react";
import BannerTop01 from "../../../resources/carousel/Banner_Top_01.jpg";
import "./item.css";

const Item1 = () => {
  return (
    <div className="col-sm-12 p-0">
      <a href="/introduce/1">
        <img
          className="img-fluid col-sm-12 p-0"
          src={BannerTop01}
          alt="banner1"
        />
      </a>
    </div>
  );
};

export default Item1;
