import * as types from "../actions/ActionTypes";

const initialState = {
  contents: [],
  currentPage: 1,
  pageLength: 1,
  postNumber: 0,
  post: {},
  my: false,
};

function board(state = initialState, action) {
  switch (action.type) {
    case types.COUNSEL_FETCH_REQUEST:
      return { ...state };
    case types.COUNSEL_FETCH_SUCCESS:
      return Object.assign({}, state, {
        contents: action.contents,
        pageLength: action.pageLength,
      });
    case types.COUNSEL_PAGE_MOVE:
      return Object.assign({}, state, {
        currentPage: action.currentPage,
      });
    case types.COUNSEL_MODE_MY:
      return Object.assign({}, state, {
        currentPage: action.currentPage,
        my: action.my,
      });
    case types.COUNSEL_READ_POST_SUCCESS:
      return Object.assign({}, state, {
        post: action.post,
      });
    default:
      return {
        ...state,
      };
  }
}

export default board;
