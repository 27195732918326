import React from "react";

import SubjectImage from "../resources/subject/subject.png";

export default class Subject extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="text-center">
        <div className="col-xl-7 d-inline-block">
          <img className="img-fluid" alt="subject" src={SubjectImage} />
        </div>
      </div>
    );
  }
}
