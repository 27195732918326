import * as types from "../actions/ActionTypes";

const initialState = {
  loginType: "",
  username: "",
};

function auth(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        loginType: action.loginType,
        username: action.username,
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
}

export default auth;
