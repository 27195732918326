import React from "react";
import HomeImage from "../../resources/breadcrumb/home.png";
import DivideImage from "../../resources/breadcrumb/devide.png";

import "./Breadcrumb.css";

const details = {
  introduce: ["인사말", "왜 푸른청인가?", "푸른청 이야기", "진료 안내", "의료진 소개"],
  A: [
    "두통",
    "어지럼증",
    "치매",
    "뇌졸중",
    "파킨슨병",
    "안면마비",
    "실신간질",
    "하지불안증후군",
    "눈떨림, 얼굴떨림",
    "만성피로",
    "수면장애",
    "손발저림",
  ],
  B: ["경추성 두통", "일자목 거북목", "척추 관절 통증", "척추 측만증"],
  community: ["후기 게시판", "상담 게시판"],
  login: ["로그인"],
};

export default class Breadcrumb extends React.Component {
  render() {
    return (
      <div className="col-md-12 breadcrumb-container ">
        <div className="container d-flex p-0 breadcrumb-responseive-container">
          <div className="breadcrumb-home d-flex text-center">
            <a href="/" className="breadcrumb-image align-self-center">
              <img width={"auto"} height={25} alt="home" src={HomeImage} />
            </a>
          </div>
          <div className="align-self-center col-md-2 d-inline breadcrumb-first my-auto text-center">
            {this.props.topMenu}
          </div>
          <div className="breadcrumb-home d-flex text-center">
            <img
              className="breadcrumb-image align-self-center"
              width={"auto"}
              height={25}
              alt="block"
              src={DivideImage}
            />
          </div>
          <div className="align-self-center col-md-2 d-inline breadcrumb-second my-auto text-center p-0">
            <span>
              {
                details[this.props.keyName][
                  this.props.index ? this.props.index : 0
                ]
              }
            </span>
          </div>
        </div>
      </div>
    );
  }
}
