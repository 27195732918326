import React from "react";
const MobileItem = ({ image, link }) => {
  return (
    <div className="col-sm-12 p-0">
      <a href={link}>
        <img className="img-fluid col-sm-12 p-0" src={image} alt="banner1" />
      </a>
    </div>
  );
};

export default MobileItem;
