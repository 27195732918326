import React from "react";
import "./Footer.css";
import FooterFAB from "./footerFAB";
import FeeModal from "./FeeModal";

import logoImage from "../../resources/footer/logo.png";
import icon1Image from "../../resources/footer/icon1.png";
import icon2Image from "../../resources/footer/icon2.png";
import icon3Image from "../../resources/footer/icon3.png";
import icon4Image from "../../resources/insta.png";
import icon5Image from "../../resources/blog.png";
import icon6Image from "../../resources/facebook.png";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  validate(title, name, phone, details) {
    if (!title || !name || !phone || !details) {
      alert("정보를 제대로 입력해주세요.");
      return false;
    } else {
      return true;
    }
  }

  submitQuickCounsel() {
    let title = this.refs.title.value;
    let name = this.refs.name.value;
    let phone = this.refs.phoneNumber.value;
    let details = this.refs.details.value;
    if (this.validate(title, name, phone, details)) {
      this.props.quickCounsel({ title, name, phone, details });
    }
  }

  submitFABQuickCounsel() {
    let title = this.refs.footerFAB.refs.title.value;
    let name = this.refs.footerFAB.refs.name.value;
    let phone = this.refs.footerFAB.refs.phoneNumber.value;
    let details = this.refs.footerFAB.refs.details.value;
    if (this.validate(title, name, phone, details)) {
      this.props.quickCounsel({ title, name, phone, details });
    }
  }

  render() {
    return (
      <div className="footer-container tect-center" id="footer-quick">
        <div className="col-lg-8 py-4 row">
          <div className="col-md-6">
            <div className="w-20">
              <img
                className="img-fluid footer-image"
                alt="하단로고"
                src={logoImage}
              />
            </div>
            <div className="footer-text my-3">
              · 병원명: 푸른청신경과의원 <br />· 주소: 대구 수성구 달구벌대로
              3205 NH 농협은행 2층 <br />· Tel: 053-795-1212 · 대표: 양준규 ·
              사업자번호: 151-90-00672 <br />· Contact{" "}
              <span className="d-inline-block">
                <span className="mx-2">
                  <img src={icon1Image} alt="하단아이콘1" />
                </span>
                pureuncheong@naver.com
              </span>
              <span
                className="d-inline-block"
                onClick={() => window.open("http://pf.kakao.com/_PuvVj/chat")}
              >
                <span className="mx-2">
                  <img src={icon2Image} alt="하단아이콘2" />
                </span>
                neurocheong
              </span>
              <span
                className="d-inline-block"
                onClick={() => window.open("https://instagram.com/neurocheong")}
              >
                <span className="mx-2">
                  <img
                    width="25"
                    height="25"
                    src={icon4Image}
                    alt="하단아이콘4"
                  />
                </span>
                인스타그램
              </span>
              <span
                className="d-inline-block"
                onClick={() =>
                  window.open("https://blog.naver.com/neurocheong1")
                }
              >
                <span className="mx-2">
                  <img
                    width="25"
                    height="25"
                    src={icon5Image}
                    alt="하단아이콘5"
                  />
                </span>
                블로그
              </span>
              <span
                className="d-inline-block"
                onClick={() =>
                  window.open("https://www.facebook.com/neurocheong")
                }
              >
                <span className="mx-2">
                  <img
                    width="25"
                    height="25"
                    src={icon6Image}
                    alt="하단아이콘6"
                  />
                </span>
                페이스북
              </span>
              <br />© 2018 Pureun Cheong Neurology Clinic. All Rights Reserved.
              <br />
              <br />
              <span>
                <button
                  className="footer-privacy-button"
                  onClick={() => window.open("/terms", "_self")}
                >
                  이용약관
                </button>
              </span>
              <span>
                <button
                  className="footer-privacy-button"
                  onClick={() => window.open("/privacy", "_self")}
                >
                  개인정보취급방침
                </button>
              </span>
              <span>
                <button
                  className="footer-privacy-button"
                  id="footer-modal-button"
                  data-toggle="modal"
                  data-target="#modal-footer-1"
                >
                  비급여항목
                </button>
              </span>
            </div>
          </div>
          <div className="footer-quick-counsel-form col-md-6 footer-text text-center">
            <span className="footer-form-title d-inline-block">
              <img
                className="footer-form-icon my-auto"
                alt="하단아이콘3"
                src={icon3Image}
              />{" "}
              빠른상담
            </span>
            <div className="my-3 w-100 text-left">
              <div className="footer-form-container">
                <div className="form-row b-0">
                  <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">이름</label>
                    <input
                      ref="name"
                      type="text"
                      className="form-control"
                      id="inputEmail4"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="inputPassword4">연락처</label>
                    <input
                      ref="phoneNumber"
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="inputAddress">제목</label>
                  <input
                    ref="title"
                    type="text"
                    className="form-control"
                    id="inputAddress"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="inputAddress2">상담내용</label>
                  <textarea
                    ref="details"
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="2"
                  />
                </div>
                <div className="form-group text-right">
                  <button
                    onClick={() => this.submitQuickCounsel()}
                    className="btn btn-primary"
                  >
                    신청
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-fab-responsive">
          <FooterFAB
            ref="footerFAB"
            onSubmit={() => {
              this.submitFABQuickCounsel();
            }}
          />
        </div>
        <FeeModal />
      </div>
    );
  }
}
