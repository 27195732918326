import Login from "../components/login/login";
import { connect } from "react-redux";
import * as actions from "../actions/Auth";

const mapStateToProps = (state) => {
  return {
    type: state.auth.type,
    username: state.auth.username,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginRequest: (type) => dispatch(actions.loginRequest(type)),
  loginCheck: () => dispatch(actions.loginCheck()),
});

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginContainer;
