import React from "react";
import "./Popup.css";
import cookie from "react-cookies";

export default class PopUp extends React.Component {
  constructor(props) {
    super(props);
    this.props.fetchPopup();
    this.state = { cookie: false, data: false };
    this.popupList = [<div />];
  }

  componentDidMount() {
    this.getCookie();
  }

  makePopupList(contenetsArray) {
    if (!Array.isArray(contenetsArray)) {
      return;
    }
    if (!contenetsArray.length) {
      return;
    }
    this.setState({ data: true });

    return contenetsArray.map((contents, index) => {
      if (index === 0) {
        return (
          <div className="carousel-item active">
            <img
              className="img-fluid img"
              src={`/popups/${contents.img}`}
              alt="popup"
            />
          </div>
        );
      } else {
        return (
          <div className="carousel-item">
            <img
              className="img-fluid img"
              src={`/popups/${contents.img}`}
              alt="popup"
            />
          </div>
        );
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!Array.isArray(nextProps.contents)) {
      return;
    }
    if (nextProps.contents.length) {
      this.popupList = this.makePopupList(nextProps.contents);
    }
  }

  getCookie() {
    const cookies = cookie.loadAll();
    if (cookies.popup) {
      this.setState({ cookie: false });
    } else {
      this.setState({ cookie: true });
    }
  }

  setCookie() {
    this.setState({ cookie: false });
    var d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = `popup=${true};${expires}`;
  }
  render() {
    return (
      <div>
        {this.state.cookie && this.state.data && (
          <div className="col-sm-12 popup text-center px-auto">
            <div
              className="col-md-4 p-0 popup-image-container carousel bg-light justify-item-center mx-auto"
              id="carouselIndicators"
              data-ride="carousel"
            >
              {this.popupList}
              <a
                className="carousel-control-prev p-3"
                href="#carouselIndicators"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next p-3"
                href="#carouselIndicators"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                />
              </a>
            </div>
            <div className="col-md-4 popup-bottom-container mx-auto">
              <div className="my-auto py-2">
                오늘 하루동안 이 창 열지 않기
                <button
                  className="mx-2 border"
                  onClick={() => {
                    this.setCookie();
                  }}
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
