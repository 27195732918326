import React from "react";
import "./login.css";

export default class Login extends React.Component {
  onSubmit() {
    this.props.loginRequest({
      id: this.refs.id.value,
      password: this.refs.password.value,
    });
  }

  render() {
    return (
      <div className="col-sm-12 container text-center row">
        {this.props.loginType ? (
          <div>이미 로그인된 상태입니다. 로그아웃을 하고 다시 눌러주세요.</div>
        ) : (
          <div className="col-sm-6 bg-light py-4 mx-auto">
            <div className="form-group col-md-12">
              <label htmlFor="id">아이디</label>
              <input
                ref="id"
                type="text"
                className="form-control"
                id="inputEmail4"
              />
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="password">비밀번호</label>
              <input
                ref="password"
                type="password"
                className="form-control"
                id="inputPassword4"
              />
            </div>
            <div className="form-group col-md-12">
              <button
                onClick={() => this.onSubmit()}
                className="btn btn-primary"
              >
                관리자로 로그인
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
