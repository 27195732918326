import React from "react";
import "./login.css";
import Breadcrumb from "../navbar/Breadcrumb";

import helloLoginImage from "../../resources/login/hello.png";
import kakaoLoginImage from "../../resources/login/kakao.png";
import naverLoginImage from "../../resources/login/naver.png";
import googleLoginImage from "../../resources/login/google.png";
import facebookLoginImage from "../../resources/login/facebook.png";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.props.loginCheck();
  }

  render() {
    return (
      <div>
        {this.props.username ? (
          <div className="container text-center">
            <div className="jumbotron bg-transparent login-text-container">
              <div className="login-text-title py-3">
                {this.props.username}님 안녕하세요 푸른청에 오신 것을
                환영합니다.
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Breadcrumb topMenu="푸른청 회원" keyName="login" index={0} />
            <div className="container text-center">
              <div className="jumbotron bg-transparent login-text-container">
                <img
                  alt="logo"
                  className="img-fluid col-lg-6"
                  src={helloLoginImage}
                />
              </div>
              <div className="col-sm-12 text-center button-container py-5 my-5">
                <div className="col-lg-6 mx-auto">
                  <div className="col-sm-12 login-arrow-container p-0 my-2">
                    <span className="login-arrow-text align-middle">
                      SNS 계정 로그인
                    </span>
                  </div>
                  <button
                    className="col-sm-12 login-button p-0 my-1"
                    onClick={() => this.props.loginRequest("kakao")}
                  >
                    <img
                      className="img-fluid"
                      src={kakaoLoginImage}
                      alt="kakaologin"
                    />
                  </button>
                  <button
                    className="col-sm-12 login-button p-0 my-1"
                    onClick={() => this.props.loginRequest("naver")}
                  >
                    <img
                      className="img-fluid"
                      src={naverLoginImage}
                      alt="naverlogin"
                    />
                  </button>
                  <button
                    className="col-sm-12 login-button p-0 my-1"
                    onClick={() => this.props.loginRequest("google")}
                  >
                    <img
                      className="img-fluid"
                      src={googleLoginImage}
                      alt="googlelogin"
                    />
                  </button>
                  <button
                    className="col-sm-12 login-button p-0 my-1"
                    onClick={() => this.props.loginRequest("facebook")}
                  >
                    <img
                      className="img-fluid"
                      src={facebookLoginImage}
                      alt="facebookLogin"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
