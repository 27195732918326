import Login from "../components/login/loginSuccess";
import { connect } from "react-redux";
import * as actions from "../actions/Auth";

const mapDispatchToProps = (dispatch) => ({
  loginSuccess: (type, username) =>
    dispatch(actions.loginSuccess(type, username)),
});

const LoginContainer = connect(null, mapDispatchToProps)(Login);

export default LoginContainer;
