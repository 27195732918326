import axios from "axios";
import * as types from "./ActionTypes";

export const fetch = () => (dispatch, getState) => {
  dispatch({ type: types.POP_UP_FETCH_REQUEST });
  const url = "/popup";
  axios({ method: "GET", url: url })
    .then((data) => {
      dispatch({
        type: types.POP_UP_FETCH_SUCCESS,
        popup: data.data.contents,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.POP_UP_FETCH_FAILURE,
        error,
      });
    });
};

export const upload = (data) => (dispatch, getState) => {
  dispatch({ type: types.POP_UP_UPLOAD_REQUEST });
  axios({
    url: "/popup",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((data) => {
      window.location.reload();
    })
    .catch((error) => {
      alert(error.response.data.message);
    });
};

export const remove = (num) => (dispatch, getState) => {
  dispatch({ type: types.POP_UP_UPLOAD_REQUEST });
  axios({
    url: `/popup/${num}`,
    method: "DELETE",
  })
    .then((data) => {
      window.location.reload();
    })
    .catch((error) => {
      alert(error.response.data.message);
    });
};
