import React from "react";

import firstImage from "../../resources/department/2/5.png";
import secondImage from "../../resources/department/2/6.png";
import thirdImage from "../../resources/department/2/7.png";
import forthImage from "../../resources/department/2/8.png";

const Subject = () => {
  return (
    <div className="w-100 text-center">
      <div className="col-sm-3 p-5" id="department-subject-image-container">
        <a href="/clinicB/1">
          <img className="img-fluid" src={firstImage} alt="subject5" />
        </a>
      </div>
      <div className="col-sm-3 p-5" id="department-subject-image-container">
        <a href="/clinicB/2">
          <img className="img-fluid" src={secondImage} alt="subject6" />
        </a>
      </div>
      <div className="col-sm-3 p-5" id="department-subject-image-container">
        <a href="/clinicB/3">
          <img className="img-fluid" src={thirdImage} alt="subject7" />
        </a>
      </div>
      <div className="col-sm-3 p-5" id="department-subject-image-container">
        <a href="/clinicB/4">
          <img className="img-fluid" src={forthImage} alt="subject8" />
        </a>
      </div>
    </div>
  );
};

export default Subject;
