import reviewContents from "../components/board/reviewContents";
import { connect } from "react-redux";
import * as actions from "../actions/Review";

const mapStateToProps = (state) => {
  return {
    post: state.review.post,
    loginType: state.auth.loginType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  read: (postNum) => dispatch(actions.readContent(postNum)),
  remove: (postNum) => dispatch(actions.removeContent(postNum)),
});

const BoardContentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(reviewContents);

export default BoardContentsContainer;
