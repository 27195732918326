import * as types from "./ActionTypes";
import axios from "axios";
import cookie from "react-cookies";

export const loginRequest = (type) => (dispatch, getState) => {
  const username = getState().auth.username;
  if (
    type !== "kakao" &&
    type !== "naver" &&
    type !== "google" &&
    type !== "facebook"
  ) {
    return dispatch({
      type: types.LOGIN_FAILURE,
      error: "잘못된 로그인 시도입니다.",
    });
  }
  if (username) {
    return dispatch({ type: types.LOGIN_ALREADY });
  }
  dispatch({ type: types.LOGIN_REQUEST });
  window.open(`login/${type}`, "_blank");
};

export const loginSuccess = () => (dispatch, getState) => {
  const cookies = cookie.loadAll();
  if (cookies.type && cookies.username) {
    return dispatch({
      type: types.LOGIN_SUCCESS,
      loginType: cookies.type,
      username: cookies.username,
    });
  } else {
    return dispatch({
      type: types.LOGIN_FAILURE,
      error: "잘못된 로그인 시도입니다.",
    });
  }
};

export const loginCheck = () => (dispatch, getState) => {
  const cookies = cookie.loadAll();
  if (cookies.type && cookies.username) {
    return dispatch({
      type: types.LOGIN_SUCCESS,
      loginType: cookies.type,
      username: cookies.username,
    });
  }
};

export const loginCheckAndRedirecForLogin = () => (dispatch, getState) => {
  const cookies = cookie.loadAll();
  if (cookies.type && cookies.username) {
    return dispatch({
      type: types.LOGIN_SUCCESS,
      loginType: cookies.type,
      username: cookies.username,
    });
  }
  alert("로그인이 필요한 서비스 입니다.");
  window.open("/signin", "_self");
};

export const adminLogin = (input) => (dispatch, getState) => {
  const username = getState().auth.username;
  if (username) {
    return dispatch({ type: types.LOGIN_ALREADY });
  }
  dispatch({ type: types.LOGIN_REQUEST });
  axios({ method: "POST", url: `/login/admin`, data: input })
    .then((data) => {
      if (data.request.responseURL) {
        window.open(data.request.responseURL, "_self");
      } else {
        window.open("/", "_self");
      }
    })
    .catch((error) => {
      alert("아이디 비밀번호를 확인하세요.");
    });
};
