import React from "react";
import MobileHomeButton from "./mobileHomeButton";
import mobileHome1Image from "../../resources/mobileHome/1.png";
import mobileHome2Image from "../../resources/mobileHome/4.png";
import mobileHome3Image from "../../resources/mobileHome/2.png";
import mobileHome4Image from "../../resources/mobileHome/3.png";

const MobileHomeButtonGroup = () => {
  return (
    <div className="col-12 row p-0 d-flex d-md-none">
      <MobileHomeButton
        icon={mobileHome1Image}
        backgroundColor={"#419dc2"}
        text="푸른청 소개"
        link={"/introduce/1"}
      />
      <MobileHomeButton
        icon={mobileHome2Image}
        backgroundColor={"#96c962"}
        text="커뮤니티"
        link={"/board/counsel"}
      />
      <MobileHomeButton
        icon={mobileHome3Image}
        backgroundColor={"#59b1cc"}
        text="뇌 신경 클리닉"
        link={"/clinicA/1"}
      />
      <MobileHomeButton
        icon={mobileHome4Image}
        backgroundColor={"#5cbeb0"}
        text="통증 클리닉"
        link={"/clinicB/1"}
      />
    </div>
  );
};

export default MobileHomeButtonGroup;
