import Review from "../components/board/review";
import { connect } from "react-redux";
import * as reviewActions from "../actions/Review";
import * as authActions from "../actions/Auth";

const mapStateToProps = (state) => {
  return {
    loginType: state.auth.loginType,
    username: state.auth.username,
    contents: state.review.contents,
    pageLength: state.review.pageLength,
    currentPage: state.review.currentPage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginCheck: () => dispatch(authActions.loginCheckAndRedirecForLogin()),
  fetch: () => dispatch(reviewActions.fetch()),
  otherPage: (pageNumber) => dispatch(reviewActions.movePage(pageNumber)),
  onSearchSubmit: (keyword) =>
    dispatch(reviewActions.setSearchKeyword(keyword)),
});

const BoardContainer = connect(mapStateToProps, mapDispatchToProps)(Review);

export default BoardContainer;
