import React, { Component } from "react";
import Navbar from "./components/navbar/Navbar";
import Prologue from "./containers/Prologue";
import Introduce from "./containers/Introduce";
import Subject from "./containers/Subject";
import Footer from "./containers/FooterContainer.js";
import ClinicA from "./containers/ClinicA.js";
import ClinicB from "./containers/ClinicB.js";
import Login from "./containers/Login";
import Admin from "./containers/Admin";
import LoginSuccess from "./containers/LoginSuccess";
import Home from "./containers/Home";
import FAB from "./components/floating/FAB";
import MobileFab from "./components/floating/MobileFab";
import Terms from "./containers/Terms";
import Privacy from "./containers/Privacy";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { logger } from "redux-logger";
import reducers from "./reducers";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import BoardRoute from "./BoardRoute";
import PopupConfig from "./containers/PopupConfig";
import Popup from "./containers/Popup";

const store = createStore(reducers, applyMiddleware(ReduxThunk));

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div>
            <Popup />
            <Navbar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/signin" component={Login} />
              <Route exact path="/admin" component={Admin} />
              <Route exact path="/admin/popup" component={PopupConfig} />
              <Route exact path="/clinica/:path" component={ClinicA} />
              <Route exact path="/clinicb/:path" component={ClinicB} />
              <Route exact path="/prologue" component={Prologue} />
              <Route exact path="/introduce/:path" component={Introduce} />
              <Route exact path="/subject" component={Subject} />
              <Route exact path="/loginSuccess" component={LoginSuccess} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/privacy" component={Privacy} />
              <BoardRoute />
            </Switch>
            <Route component={Footer} />
            <FAB />
            <MobileFab />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
