import React from "react";

import firstImage from "../../resources/department/1/1.png";
import secondImage from "../../resources/department/1/2.png";
import thirdImage from "../../resources/department/1/3.png";
import forthImage from "../../resources/department/1/4.png";

const Subject = () => {
  return (
    <div className="w-100 text-center">
      <div className="col-sm-3 p-5" id="department-subject-image-container">
        <a href="/clinicA/1">
          <img className="img-fluid" src={firstImage} alt="subject1" />
        </a>
      </div>
      <div className="col-sm-3 p-5" id="department-subject-image-container">
        <a href="/clinicA/2">
          <img className="img-fluid" src={secondImage} alt="subject2" />
        </a>
      </div>
      <div className="col-sm-3 p-5" id="department-subject-image-container">
        <a href="/clinicA/8">
          <img className="img-fluid" src={thirdImage} alt="subject3" />
        </a>
      </div>
      <div className="col-sm-3 p-5" id="department-subject-image-container">
        <a href="/clinicA/10">
          <img className="img-fluid" src={forthImage} alt="subject4" />
        </a>
      </div>
    </div>
  );
};

export default Subject;
