import Footer from "../components/footer/Footer";
import { connect } from "react-redux";
import * as actions from "../actions/Board";

const mapDispatchToProps = (dispatch) => ({
  quickCounsel: (input) => dispatch(actions.quickCounsel(input)),
});

const FooterContainer = connect(null, mapDispatchToProps)(Footer);

export default FooterContainer;
