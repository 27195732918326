import React from "react";

const MobileHomeButton = ({ icon, backgroundColor, text, link }) => {
  return (
    <a
      className="col-6 d-flex py-2 px-3 justify-item-center"
      style={{ backgroundColor }}
      href={link}
    >
      <div className="col-3 mr-3 my-auto ">
        <img
          src={icon}
          style={{ height: "30px", width: "30px" }}
          alt="mobile-home"
        />
      </div>
      <div
        className="my-auto p-0 mx-1"
        style={{
          color: "white",
          fontSize: "0.8rem",
          fontWeight: "300",
          fontFamily: "Noto Sans",
        }}
      >
        {text}
      </div>
    </a>
  );
};

export default MobileHomeButton;
