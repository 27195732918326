import React from "react";
import Board from "./containers/Board";
import Review from "./containers/Review";
import BoardContents from "./containers/BoardContents";
import BoardWrite from "./containers/BoardWrite";
import ReviewContents from "./containers/ReviewContents";
import ReviewWrite from "./containers/ReviewWrite";
import Redirect from "./containers/Redirect";

import { connect } from "react-redux";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const BoardRoute = () => {
  return (
    <Switch>
      {/* <Route path="/board" component={Redirect} /> */}
      <Route exact path="/board/review" component={Review} />
      <Route exact path="/board/counsel" component={Board} />
      <Route exact path="/board/counsel/write" component={BoardWrite} />
      <Route exact path="/board/review/write" component={ReviewWrite} />
      <Route strict path="/board/counsel/:path" component={BoardContents} />
      <Route strict path="/board/review/:path" component={ReviewContents} />
    </Switch>
  );
};

const BoardRouteContainer = connect(null, null)(BoardRoute);

export default BoardRouteContainer;
