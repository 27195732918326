import React from "react";
import Breadcrumb from "../components/navbar/Breadcrumb";

import clinicA1Image from "../resources/clinicA/1.png";
import clinicA2Image from "../resources/clinicA/2.png";
import clinicA3Image from "../resources/clinicA/3.png";
import clinicA4Image from "../resources/clinicA/4.png";
import clinicA5Image from "../resources/clinicA/5.png";
import clinicA6Image from "../resources/clinicA/6.png";
import clinicA7Image from "../resources/clinicA/7.png";
import clinicA8Image from "../resources/clinicA/8.png";
import clinicA9Image from "../resources/clinicA/9.png";
import clinicA10Image from "../resources/clinicA/10.png";
import clinicA11Image from "../resources/clinicA/11.png";
import clinicA12Image from "../resources/clinicA/12.png";

const images = [
  clinicA1Image,
  clinicA2Image,
  clinicA3Image,
  clinicA4Image,
  clinicA5Image,
  clinicA6Image,
  clinicA7Image,
  clinicA8Image,
  clinicA9Image,
  clinicA10Image,
  clinicA11Image,
  clinicA12Image,
];

export default class ClinicA extends React.Component {
  constructor(props) {
    super(props);
    this.image = images[this.props.match.params.path - 1];
    if (!this.image) {
      this.image = images[0];
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Breadcrumb
          topMenu="뇌신경클리닉"
          keyName={"A"}
          index={this.props.match.params.path - 1}
        />
        <div className="text-center">
          <div className="col-xl-5 d-inline-block">
            <div className="w-100 my-4" style={{ height: "10px" }} />
            <div>
              <img className="img-fluid" src={this.image} alt="1" />
            </div>
            <div className="w-100 my-4" style={{ height: "10px" }} />
          </div>
        </div>
      </div>
    );
  }
}
