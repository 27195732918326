import Board from "../components/board/board";
import { connect } from "react-redux";
import * as actions from "../actions/Board";

const mapStateToProps = (state) => {
  return {
    my: state.board.my,
    contents: state.board.contents,
    pageLength: state.board.pageLength,
    currentPage: state.board.currentPage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(actions.fetchCounselBoard()),
  otherPage: (pageNumber) => dispatch(actions.movePage(pageNumber)),
  searchMyContents: () => dispatch(actions.myContentsMode()),
});

const BoardContainer = connect(mapStateToProps, mapDispatchToProps)(Board);

export default BoardContainer;
