import reviewEditor from "../components/board/reviewEditor";
import { connect } from "react-redux";
import * as actions from "../actions/Review";

const mapStateToProps = (state) => {
  return {
    loginType: state.auth.loginType,
    username: state.auth.username,
  };
};

const mapDispatchToProps = (dispatch) => ({
  writeContent: (input) => dispatch(actions.writeContent(input)),
});

const BoardWrite = connect(mapStateToProps, mapDispatchToProps)(reviewEditor);

export default BoardWrite;
