import PopupConfig from "../components/popup/PopupConfig";
import { connect } from "react-redux";
import * as actions from "../actions/Popup";

const mapStateToProps = (state) => ({
  contents: state.popup.contents,
  loginType: state.auth.loginType,
});
const mapDispatchToProps = (dispatch) => ({
  fetchPopup: () => dispatch(actions.fetch()),
  uploadPopup: (data) => dispatch(actions.upload(data)),
  removePopup: (number) => dispatch(actions.remove(number)),
});

const PopupConfigContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PopupConfig);

export default PopupConfigContainer;
