import React from "react";

export default class BoardController extends React.Component {
  constructor(props) {
    super(props);
    this.state = { keyword: "" };
  }
  render() {
    //   my,
    //   board,
    //   search,
    //   admin,
    //   setParams,
    //   getMyContents
    return (
      <div className="my-3 boardController-container d-flex justify-content-between p-3 row">
        {this.props.board === "/board/counsel" && (
          <div className="col-12">
            <button
              type="button"
              style={
                this.props.board === "/board/counsel" ? {} : { display: "none" }
              }
              className="board-button align-self-center float-left col-md-1 col-sm-2 col-3 p-0 board-controller-button-background"
              onClick={() => {
                this.props.setParams();
                this.props.getMyContents();
              }}
            >
              {this.props.my ? "전체글" : "내글보기"}
            </button>
            <button
              type="button"
              className="board-button float-right align-self-center col-md-1 col-sm-2 col-3 p-0 board-controller-button-background"
              onClick={() => window.open(`${this.props.board}/write`, "_self")}
            >
              글쓰기
            </button>
          </div>
        )}
        {this.props.board === "/board/review" && (
          <div
            className="form-group text-center my-auto align-self-center col-12"
            role="group"
            aria-label="search"
            style={this.props.search ? {} : { display: "none" }}
          >
            <select
              className="board-select align-self-center text-center col-md-1 col-sm-2 col-3 p-0 text-center"
              defaultValue="제목"
            >
              <option className="board-button text-center col-12 p-0">
                제목
              </option>
            </select>
            <input
              type="text"
              className="board-input-box mx-2 align-self-center col-md-2 col-sm-3 col-4"
              onChange={(e) => {
                this.setState({ keyword: e.target.value });
              }}
            />
            <button
              type="button"
              className="board-button align-self-center col-md-1 col-sm-2 col-3 p-0"
              onClick={() => this.props.onSearchSubmit()}
            >
              검색
            </button>
            {this.props.admin && (
              <button
                type="button"
                className="board-button float-right align-self-center col-md-1 col-sm-2 col-3 p-0"
                onClick={() =>
                  window.open(`${this.props.board}/write`, "_self")
                }
              >
                글쓰기
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

BoardController.defaultProps = {
  setParams: () => {},
  getMyContents: () => {},
  onSearchSubmit: () => {},
};
