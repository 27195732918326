import BoardEditor from "../components/board/boardEditor";
import { connect } from "react-redux";
import * as actions from "../actions/Board";
import * as authActions from "../actions/Auth";

const mapStateToProps = (state) => {
  return {
    loginType: state.auth.loginType,
    username: state.auth.username,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginRequest: (type) => dispatch(authActions.loginRequest(type)),
  loginCheck: () => dispatch(authActions.loginCheckAndRedirecForLogin()),
  writeContent: (input) => dispatch(actions.writeContent(input)),
});

const BoardWrite = connect(mapStateToProps, mapDispatchToProps)(BoardEditor);

export default BoardWrite;
