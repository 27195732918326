import Popup from "../components/popup/Popup";
import { connect } from "react-redux";
import * as actions from "../actions/Popup";

const mapStateToProps = (state) => {
  return {
    contents: state.popup.contents,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPopup: () => dispatch(actions.fetch()),
});

const PopupContainer = connect(mapStateToProps, mapDispatchToProps)(Popup);

export default PopupContainer;
