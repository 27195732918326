import React from "react";
import "./Header.css";

const Header = ({ image }) => {
  return (
    <div className="mx-auto my-0 col-sm-6 col-8 p-2" id="header-container">
      <img className="img-fluid" src={image} alt="header" />
    </div>
  );
};

export default Header;
