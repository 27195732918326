import React from "react";
import BannerTop03 from "../../../resources/carousel/Banner_Top_03.jpg";
import "./item.css";

const Item3 = () => {
  return (
    <div className="col-sm-12 p-0">
      <a href="/clinicB/1">
        <img
          className="img-fluid col-sm-12 p-0"
          src={BannerTop03}
          alt="banner3"
        />
      </a>
    </div>
  );
};

export default Item3;
