import axios from "axios";
import * as types from "./ActionTypes";

const COUNSEL_FETCH_URL = "/counsel/page/";
const COUNSEL_MY_FETCH_URL = "/counsel/my/";

export const fetchCounselBoard = () => (dispatch, getState) => {
  const currentPage = getState().board.currentPage;
  const my = getState().board.my;
  let url = "";
  if (my) {
    url = COUNSEL_MY_FETCH_URL + currentPage;
  } else {
    url = COUNSEL_FETCH_URL + currentPage;
  }
  dispatch({ type: types.COUNSEL_FETCH_REQUEST });
  axios({ method: "GET", url })
    .then((data) => {
      if (data.data.pages === "0") {
        dispatch({
          type: types.COUNSEL_FETCH_SUCCESS,
          contents: [],
          pageLength: 1,
        });
        return;
      }
      if (data.data.contents) {
        dispatch({
          type: types.COUNSEL_FETCH_SUCCESS,
          contents: data.data.contents,
          pageLength: data.data.pages,
        });
        return;
      }
      return dispatch({
        type: types.COUNSEL_FETCH_FAILURE,
      });
    })
    .catch((error) => {
      dispatch({
        type: types.COUNSEL_FETCH_FAILURE,
      });
    });
};

export const myContentsMode = () => (dispatch, getState) => {
  const my = getState().board.my;
  const user = getState().auth.loginType;
  if (!user) {
    window.open("/signin", "_self");
    return dispatch({ type: types.NEED_LOGIN });
  }
  if (my) {
    dispatch({ type: types.COUNSEL_MODE_MY, my: false, currentPage: 1 });
  } else {
    dispatch({ type: types.COUNSEL_MODE_MY, my: true, currentPage: 1 });
  }
};

export const movePage = (pageNumber) => (dispatch, getState) => {
  dispatch({ type: types.COUNSEL_PAGE_MOVE, currentPage: pageNumber });
};

export const removeContent = (postNumber) => (dispatch, getState) => {
  dispatch({ type: types.COUNSEL_REMOVE_POST_REQUEST });
  return axios({ method: "DELETE", url: `/counsel/${postNumber}` })
    .then((data) => {
      dispatch({ type: types.COUNSEL_REMOVE_POST_SUCCESS });
      alert("삭제되었습니다.");
      window.open("/board/counsel", "_self");
    })
    .catch((error) => {
      dispatch({ type: types.COUNSEL_REMOVE_POST_FAILURE, error });
      alert("잘못된 요청입니다.");
      window.open("/board/counsel", "_self");
    });
};

export const readContent = (postNumber) => (dispatch, getState) => {
  dispatch({ type: types.COUNSEL_READ_POST_REQUEST });
  return axios({ method: "GET", url: `/counsel/${postNumber}` })
    .then((data) => {
      dispatch({ type: types.COUNSEL_READ_POST_SUCCESS, post: data.data });
    })
    .catch((error) => {
      dispatch({ type: types.COUNSEL_READ_POST_FAILURE });
    });
};

export const writeContent = (input) => (dispatch, getState) => {
  dispatch({ type: types.COUNSEL_WRITE_POST_REQUEST });
  return axios({ method: "POST", url: "/counsel", data: input })
    .then((data) => {
      if (data.data.message) {
        alert(data.data.message);
        return window.open("/board/counsel", "_self");
      } else {
        alert("잘못된 접근입니다.");
        window.history.back();
        return;
      }
    })
    .catch((error) => {
      alert("잘못된 접근입니다.");
      window.history.back();
      return;
    });
};

export const submitAnswer = (num, answer) => (dispatch, getState) => {
  dispatch({ type: types.COUNSEL_WRITE_ANSWER_REQUEST });
  return axios({
    method: "POST",
    url: `/counsel/answer/${num}`,
    data: { answer },
  })
    .then(() => {
      dispatch({ type: types.COUNSEL_WRITE_ANSWER_SUCCESS });
      alert("작성완료 되었습니다.");
      window.open(window.location, "_self");
      return;
    })
    .catch((error) => {
      dispatch({ type: types.COUNSEL_WRITE_ANSWER_FAILURE, error });
      alert("작성실패. 다시 시도해주십시오.");
      window.open(window.location, "_self");
      return;
    });
};

export const removeAnswer = (num) => (dispatch, getState) => {
  dispatch({ type: types.COUNSEL_WRITE_ANSWER_REQUEST });
  return axios({
    method: "DELETE",
    url: `/counsel/answer/${num}`,
  })
    .then(() => {
      dispatch({ type: types.COUNSEL_WRITE_ANSWER_SUCCESS });
      alert("삭제되었습니다.");
      window.open(window.location, "_self");
      return;
    })
    .catch((error) => {
      dispatch({ type: types.COUNSEL_WRITE_ANSWER_FAILURE, error });
      alert("작성실패. 다시 시도해주십시오.");
      window.open(window.location, "_self");
      return;
    });
};

export const quickCounsel = (input) => (dispatch, getState) => {
  dispatch({ type: types.QUICK_COUNSEL_REQUEST });
  return axios({ method: "POST", url: `/quick-counsel`, data: input })
    .then((data) => {
      dispatch({ type: types.QUICK_COUNSEL_SUCCESS });
      alert("상담 내용이 정상적으로 전송되었습니다.");
    })
    .catch((error) => {
      dispatch({ type: types.QUICK_COUNSEL_FAILURE, error });
    });
};
