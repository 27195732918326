import React from "react";
import BannerWithLogo from "../../resources/brand_with_logo.png";
import CollapseImage from "../../resources/navbar/collapse.png";
import "./Navbar.css";
import NavbarLoginButton from "./NavbarLoginButton";

export default class Navbar extends React.Component {
  render() {
    return (
      <div className="pos-f-t sticky-top">
        <nav className="navbar navbar-expand-md p-0 navbar-background-color col-12 px-3 py-2">
          <div className="p-0 col-12 d-md-none d-flex justify-content-between">
            <span style={{ width: "56px", height: "50px" }} />
            <a className="navbar-brand px-3 mx-0" href="/">
              <img
                src={BannerWithLogo}
                width="120"
                height="40"
                className="d-inline-block align-top"
                alt=""
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarToggleExternalContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <img alt="icon" className="img-fluid" src={CollapseImage} />
              </span>
            </button>
          </div>
          <div className="mx-auto col-xl-8 p-0">
            <div
              className="navbar-nav pr-2 justify-content-around navbar-collapse collapse navbar-responsive"
              id="navbarToggleExternalContent"
            >
              <div
                className="navbar-collapse-blinder"
                data-toggle="collapse"
                data-target="#navbarToggleExternalContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              />
              <div className="navbar-nav d-md-flex navbar-collapse-contents col-12 p-0 justify-content-around">
                <a className="navbar-brand px-3 d-md-block d-none" href="/">
                  <img
                    src={BannerWithLogo}
                    width="184"
                    height="60"
                    className="d-inline-block align-top"
                    alt=""
                  />
                </a>
                <div className="dropdown w-100 text-center">
                  <span className="mx-2">
                    <a
                      href="#"
                      className="nav-item"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      푸른청소개
                    </a>
                    <div
                      className="dropdown-menu w-100"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/introduce/1">
                        <span className="dropdown-middledot">&middot;</span>
                        인사말
                      </a>
                      <a className="dropdown-item" href="/introduce/2">
                        <span className="dropdown-middledot">&middot;</span>왜
                        푸른청인가?
                      </a>
                      <a className="dropdown-item" href="/introduce/5">
                        <span className="dropdown-middledot">&middot;</span>
                        의료진 소개
                      </a>
                      <a className="dropdown-item" href="/introduce/3">
                        <span className="dropdown-middledot">&middot;</span>
                        푸른청 이야기
                      </a>
                      <a className="dropdown-item" href="/introduce/4">
                        <span className="dropdown-middledot">&middot;</span>
                        진료 안내
                      </a>
                    </div>
                  </span>
                </div>
                <span className="nav-vertical-devider my-auto" />
                <div className="dropdown w-100 text-center">
                  <span className="mx-2">
                    <a
                      href="#"
                      className="nav-item"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      뇌신경클리닉
                    </a>
                    <div className="dropdown-menu w-100 mobile-nav-double">
                      <a className="dropdown-item" href="/clinicA/1">
                        <span className="dropdown-middledot">&middot;</span>
                        두통
                      </a>
                      <a className="dropdown-item" href="/clinicA/2">
                        <span className="dropdown-middledot">&middot;</span>
                        어지럼증
                      </a>
                      <a className="dropdown-item" href="/clinicA/3">
                        <span className="dropdown-middledot">&middot;</span>
                        치매
                      </a>
                      <a className="dropdown-item" href="/clinicA/4">
                        <span className="dropdown-middledot">&middot;</span>
                        뇌졸중
                      </a>
                      <a className="dropdown-item" href="/clinicA/5">
                        <span className="dropdown-middledot">&middot;</span>
                        파킨슨병
                      </a>
                      <a className="dropdown-item" href="/clinicA/6">
                        <span className="dropdown-middledot">&middot;</span>
                        안면마비
                      </a>
                      <a className="dropdown-item" href="/clinicA/7">
                        <span className="dropdown-middledot">&middot;</span>
                        실신, 간질
                      </a>
                      <a className="dropdown-item" href="/clinicA/8">
                        <span className="dropdown-middledot">&middot;</span>
                        하지불안증후군
                      </a>
                      <a className="dropdown-item" href="/clinicA/9">
                        <span className="dropdown-middledot">&middot;</span>
                        눈떨림, 얼굴떨림
                      </a>
                      <a className="dropdown-item" href="/clinicA/10">
                        <span className="dropdown-middledot">&middot;</span>
                        만성피로
                      </a>
                      <a className="dropdown-item" href="/clinicA/11">
                        <span className="dropdown-middledot">&middot;</span>
                        수면장애
                      </a>
                      <a className="dropdown-item" href="/clinicA/12">
                        <span className="dropdown-middledot">&middot;</span>
                        손발저림
                      </a>
                    </div>
                  </span>
                </div>
                <span className="nav-vertical-devider my-auto" />
                <div className="dropdown w-100 text-center">
                  <span className="mx-2">
                    <a
                      href="#"
                      className="nav-item"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      통증클리닉
                    </a>
                    <div className="dropdown-menu w-100">
                      <a className="dropdown-item" href="/clinicB/1">
                        <span className="dropdown-middledot">&middot;</span>
                        경추성 두통
                      </a>
                      <a className="dropdown-item" href="/clinicB/2">
                        <span className="dropdown-middledot">&middot;</span>
                        일자목, 거북목
                      </a>
                      <a className="dropdown-item" href="/clinicB/3">
                        <span className="dropdown-middledot">&middot;</span>
                        척추 관절 통증
                      </a>
                      <a className="dropdown-item" href="/clinicB/4">
                        <span className="dropdown-middledot">&middot;</span>
                        척추측만증
                      </a>
                    </div>
                  </span>
                </div>
                <span className="nav-vertical-devider my-auto" />
                <div className="dropdown w-100 text-center">
                  <span className="mx-2">
                    <a
                      href="#"
                      className="nav-item"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      커뮤니티
                    </a>
                    <div className="dropdown-menu w-100">
                      <a
                        className="dropdown-item"
                        id="dropdownMenuLink"
                        href="/board/review"
                      >
                        <span className="dropdown-middledot">&middot;</span>
                        후기 게시판
                      </a>
                      <a className="dropdown-item" href="/board/counsel">
                        <span className="dropdown-middledot">&middot;</span>
                        상담 게시판
                      </a>
                    </div>
                  </span>
                </div>
                <NavbarLoginButton />
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
