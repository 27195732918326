import axios from "axios";
import * as types from "./ActionTypes";
const FETCH_REVIEW_URL = "/review/page/";

export const fetch = () => (dispatch, getState) => {
  const currentPage = getState().review.currentPage;
  let url = FETCH_REVIEW_URL + currentPage;
  dispatch({ type: types.REVIEW_FETCH_REQUEST });
  const keyword = getState().review.keyword;
  if (keyword) {
    url += `?search=${keyword}`;
  }
  // /review/page/{pageNum}?search={word}
  axios({ method: "GET", url })
    .then((data) => {
      dispatch({
        type: types.REVIEW_FETCH_SUCCESS,
        contents: data.data.contents,
        pageLength: data.data.pages,
      });
    })
    .catch(() => {
      dispatch({
        type: types.REVIEW_FETCH_FAILURE,
      });
      // alert("로그인이 필요한 서비스입니다.");
      // window.open("/signin", "_self");
    });
};

export const movePage = (pageNumber) => (dispatch, getState) => {
  dispatch({ type: types.REVIEW_PAGE_MOVE, currentPage: pageNumber });
};

export const setSearchKeyword = (keyword) => (dispatch, getState) => {
  dispatch({ type: types.REVIEW_MODE_SEARCH, currentPage: 1, keyword });
};

export const readContent = (postNumber) => (dispatch, getState) => {
  dispatch({ type: types.REVIEW_READ_POST_REQUEST });
  return axios({ method: "GET", url: `/review/${postNumber}` })
    .then((data) => {
      dispatch({ type: types.REVIEW_READ_POST_SUCCESS, post: data.data });
    })
    .catch((error) => {
      dispatch({ type: types.REVIEW_READ_POST_FAILURE });
      // alert("로그인이 필요한 서비스입니다.");
      // window.history.back();
    });
};

export const writeContent = (input) => (dispatch, getState) => {
  dispatch({ type: types.REVIEW_WRITE_POST_REQUEST });
  return axios({
    method: "POST",
    url: "/review",
    data: input,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((data) => {
      alert("작성이 완료되었습니다.");
      window.open("/board/review", "_self");
    })
    .catch((error) => {
      alert("잘못된 요청입니다.");
      window.open("/board/review", "_self");
    });
};

export const removeContent = (postNumber) => (dispatch, getState) => {
  dispatch({ type: types.REVIEW_REMOVE_POST_REQUEST });
  return axios({ method: "DELETE", url: `/review/${postNumber}` })
    .then((data) => {
      dispatch({ type: types.REVIEW_REMOVE_POST_SUCCESS, post: data.data });
      alert("삭제되었습니다.");
      window.history.back();
    })
    .catch((error) => {
      dispatch({ type: types.REVIEW_REMOVE_POST_FAILURE, error });
      alert("잘못된 요청입니다.");
      window.history.back();
    });
};
