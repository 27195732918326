import React from "react";
import "./FAB.css";

const FABItem = ({ image, title, backgroundColor, buttonAction }) => {
  return (
    <button
      type="button"
      className="fab-button-container row align-items-center"
      style={{ backgroundColor }}
      onClick={() => buttonAction()}
    >
      <div className="fab-image-container mx-1 text-center">
        <img className="img-fluid fab-button-image" src={image} alt="fab" />
      </div>
      <div className=" fab-button-title mx-1">{title}</div>
    </button>
  );
};

export default FABItem;
