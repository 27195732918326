export const MATERIALS_FEES = [
  { primaryCategory: "기타", code: "복대", name: "DR.MED-CORSET(허리)-", distinction: "", fee: "55000", maxFee: "", minFee: "", significantReport: "", lastEditDate: "20221214"},
  { primaryCategory: "기타", code: "캐스트슈즈", name: "캐스트슈즈", distinction: "", fee: "5000", maxFee: "", minFee: "", significantReport: "", lastEditDate: "20221214"},
]

export const DRUG_FEES = [
  { primaryCategory: "기타", code: "643308471", name: "원더톡스주100단위(클로스트리디움보툴리눔독소A형)_(100U/1병)", fee: "200000", significantReport: "", lastEditDate: "20221214"},
  { primaryCategory: "기타", code: "644913140", name: "페린젝트주(수산화제이철카르복시말토오스복염)", fee: "300000", significantReport: "", lastEditDate: "20221214"},
  { primaryCategory: "기타", code: "654400571", name: "삭센다펜주6밀리그램/밀리리터(리라글루티드)", fee: "130000", significantReport: "", lastEditDate: "20221214"},
  { primaryCategory: "기타", code: "669906221", name: "바이타디주(콜레칼시페롤)", fee: "40000", significantReport: "", lastEditDate: "20221214"},
  { primaryCategory: "기타", code: "669907271", name: "티모신주(싸이모신알파1)", fee: "100000", significantReport: "", lastEditDate: "20221214"},
];

export const CERTIFICATIONS_FEES = [
  { primaryCategory: "기타", code: "CD복사", name: "CD복사", distinction:"", fee: "10000", significantReport: "", lastEditDate: "20221219"},
  { primaryCategory: "기타", code: "검사결과", name: "검사결과지", distinction:"", fee: "3000", significantReport: "", lastEditDate: "20221219"},
  { primaryCategory: "기타", code: "병무용진단서", name: "병무용진단서", distinction:"", fee: "20000", significantReport: "", lastEditDate: "20221219"},
  { primaryCategory: "기타", code: "산재소견서", name: "산재소견서", distinction:"", fee: "20000", significantReport: "", lastEditDate: "20221219"},
  { primaryCategory: "기타", code: "소견서", name: "소견서", distinction:"", fee: "10000", significantReport: "", lastEditDate: "20221219"},
  { primaryCategory: "기타", code: "소견서(보험회사)", name: "소견서(보험회사)", distinction:"", fee: "20000", significantReport: "", lastEditDate: "20221219"},
  { primaryCategory: "기타", code: "장애인증명서", name: "장애인증명서", distinction:"", fee: "1000", significantReport: "", lastEditDate: "20221219"},
  { primaryCategory: "기타", code: "재발급서류", name: "재발급(진단서,소견서 등)", distinction:"", fee: "1000", significantReport: "", lastEditDate: "20221219"},
  { primaryCategory: "기타", code: "진단서", name: "진단서", distinction:"", fee: "10000", significantReport: "", lastEditDate: "20221219"},
  { primaryCategory: "기타", code: "진료기록부", name: "진료기록부(1~5장)", distinction:"", fee: "1000", significantReport: "", lastEditDate: "20221219"},
  { primaryCategory: "기타", code: "진료기록사본", name: "진료기록사본(6장 이상)", distinction:"", fee: "100", significantReport: "", lastEditDate: "20221219"},
  { primaryCategory: "기타", code: "통원확인서", name: "통원확인서(본인)", distinction:"", fee: "3000", significantReport: "", lastEditDate: "20221219"},
]

export const TREATMENT_FEES = [
  {primaryCategory: "예방접종료", secondaryCategory: "대상포진", code: "3Z5200302", name: "대상포진-조스타박스주", distinction: "", fee: "170000", minFee: "", maxFee: "", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "", lastEditDate: "20221114"},
  {primaryCategory: "예방접종료", secondaryCategory: "인플루엔자", code: "3Z5201110", name: "인플루엔자-박씨그리프테트라주", distinction: "", fee: "45000", minFee: "", maxFee: "", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "", lastEditDate: "20221114"},
  {primaryCategory: "예방접종료", secondaryCategory: "폐렴구균", code: "3Z5201701", name: "폐렴구균-프리베나13주", distinction: "", fee: "130000", minFee: "", maxFee: "", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "", lastEditDate: "20221114"},
  {primaryCategory: "기능검사료 (외피, 근골 기능 검사)", secondaryCategory: "체온열검사", code: "EZ7760001", name: "체온열검사-전신", distinction: "", fee: "", minFee: "30000", maxFee: "100000", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "부위에따라 금액변동", lastEditDate: "20221114"},
  {primaryCategory: "기능검사료 (신경계 기능검사)", secondaryCategory: "초음파를 활용한 뇌혈류 기능검사", code: "FZ7140000", name: "초음파를 활용한 뇌혈류 기능검사", distinction: "", fee: "100000", minFee: "", maxFee: "", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "", lastEditDate: "20221114"},
  {primaryCategory: "처치 및 수술료(기타)", secondaryCategory: "경두개자기자극술", code: "QZ9620000", name: "경두개자기자극술", distinction: "", fee: "", minFee: "50000", maxFee: "100000", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "시간에따라 금액변동", lastEditDate: "20221114"},
  {primaryCategory: "기타", secondaryCategory: "기타", code: "EB470", name: "근골격, 연부-연부조직 초음파-일반", distinction: "", fee: "100000", minFee: "30000", maxFee: "100000", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "부위에따라 금액변동", lastEditDate: "20221114"},
  {primaryCategory: "기타", secondaryCategory: "기타", code: "EZ877", name: "경동맥 초음파", distinction: "", fee: "100000", minFee: "", maxFee: "", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "", lastEditDate: "20221114"},
  {primaryCategory: "기타", secondaryCategory: "기타", code: "FY891", name: "자율신경계이상검사(기립성혈압검사)", distinction: "", fee: "70000", minFee: "", maxFee: "", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "", lastEditDate: "20221114"},
  {primaryCategory: "기타", secondaryCategory: "기타", code: "FY894", name: "자율신경계이상검사(심박변이도검사)", distinction: "", fee: "50000", minFee: "", maxFee: "", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "", lastEditDate: "20221114"},
  {primaryCategory: "기타", secondaryCategory: "기타", code: "FZ701", name: "수면각성활동량검사", distinction: "", fee: "200000", minFee: "", maxFee: "", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "", lastEditDate: "20221114"},
  {primaryCategory: "기타", secondaryCategory: "기타", code: "FZ733", name: "비디오전기안진검사", distinction: "", fee: "50000", minFee: "", maxFee: "", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "", lastEditDate: "20221114"},
  {primaryCategory: "기타", secondaryCategory: "기타", code: "MM085", name: "재활저출력레이저치료[1일당]", distinction: "", fee: "5500", minFee: "", maxFee: "", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "", lastEditDate: "20221114"},
  {primaryCategory: "기타", secondaryCategory: "기타", code: "MX122", name: "도수치료 [1일당]", distinction: "", fee: "", minFee: "100000", maxFee: "300000", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "시간에따라 금액변동", lastEditDate: "20221114"},
  {primaryCategory: "기타", secondaryCategory: "기타", code: "MY142", name: "증식치료(사지관절부위)", distinction: "", fee: "", minFee: "100000", maxFee: "300000", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "부위에따라 금액변동", lastEditDate: "20221114"},
  {primaryCategory: "기타", secondaryCategory: "기타", code: "MY143", name: "증식치료(척추부위)", distinction: "", fee: "", minFee: "100000", maxFee: "300000", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "부위에따라 금액변동", lastEditDate: "20221114"},
  {primaryCategory: "기타", secondaryCategory: "기타", code: "MZ016", name: "맞춤 전정 운동", distinction: "", fee: "100000", minFee: "", maxFee: "", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "", lastEditDate: "20221114"},
  {primaryCategory: "기타", secondaryCategory: "기타", code: "SZ084", name: "체외충격파치료[근골격계질환]", distinction: "", fee: "", minFee: "30000", maxFee: "300000", isIncludingMaterialFee: "0", isIncludingMaterialFee: "0", significantReport: "타수, 부위에따라 금액변동", lastEditDate: "20221114"},
]