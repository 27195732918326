import React from "react";

const HomeCardItem = ({ id, image1, image2, image3, link }) => {
  return (
    <div className="col-sm-4 homecard-column-container">
      <div className="homecard-inner-container">
        <div id={id} className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target={`#${id}`} data-slide-to="0" className="active" />
            <li data-target={`#${id}`} data-slide-to="1" />
            <li data-target={`#${id}`} data-slide-to="2" />
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <a href={link[0]}>
                <img className="img-fluid" src={image1} alt="배너" />
              </a>
            </div>
            <div className="carousel-item">
              <a href={link[1]}>
                <img className="img-fluid" src={image2} alt="배너" />
              </a>
            </div>
            <div className="carousel-item">
              <a href={link[2]}>
                <img className="img-fluid" src={image3} alt="배너" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCardItem;
