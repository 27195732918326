import React from "react";
import moment from "moment-timezone";

export default class PopupUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { date: '', file: null };
  }

  onPress() {
    if (!this.state.file) {
      alert("파일이 선택되지 않았습니다.");
      return false;
    }

    var formData = new FormData();
    if (moment(this.state.date).isValid() && moment(this.state.date).isAfter(moment())) {
      formData.set("date", this.state.date);
    } else {
      formData.set("date", "");
    }
    formData.set("popup", this.state.file);
    this.props.onSubmit(formData);
  }

  dateValidation() {
    return moment(this.state.date).isValid() && moment(this.state.date).isAfter(moment())
  }

  render() {
    return (
      <div className="form-group container">
        <div className="form-group">
          <label for="date">
            만료일자(입력하지 않거나, 바르지 않은 값이 입력될 시 기본값으로 한
            달이 적용됩니다.)
          </label>
          <input
            type="number"
            className="form-control"
            id="exampleFormControlFile1"
            placeholder="YYMMDD"
            onChange={(e) => this.setState({ date: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label for="file">이미지파일(jpg, jpeg, gif, png)</label>
          <input
            className="form-control-file"
            ref="file"
            type="file"
            name="image"
            onChange={(e) => this.setState({ file: e.target.files[0] })}
          />
        </div>
        <button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            this.onPress();
          }}
          className="btn btn-primary"
        >
          작성완료
        </button>
      </div>
    );
  }
}
