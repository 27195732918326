import React from "react";
import "./boardEditor.css";

export default class BoardEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleLength: 0,
      contentLength: 0,
      nameLength: 0,
      file: null,
    };
  }

  onPress() {
    if (this.validateInputValue()) {
      this.props.writeContent(this.validateInputValue());
    }
  }

  validateInputValue() {
    let title = this.refs.title.value;
    let content = this.refs.content.value;

    if (2 > title.length || title.length > 30) {
      alert("제목 2자 ~ 30자로 입력해주세요.");
      return false;
    }
    // 제목 2~30자
    if (10 > content.length || content.length > 2000) {
      alert("내용 10자 ~ 2000자로 입력해주세요.");
      return false;
    }
    var formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("image", this.state.file);
    return formData;
  }

  render() {
    return (
      <div className="container">
        <div style={{ width: "100%", height: "20px" }} />
        {this.props.loginType === "admin" && (
          <form className="form-horizontal">
            <table className="table table-border board-editor-form">
              <thead>
                <tr>
                  <td>
                    글 제목(*)&nbsp;&nbsp;
                    {this.state.titleLength}
                    /30
                  </td>
                  <td>
                    <input
                      onChange={(e) =>
                        this.setState({ titleLength: e.target.value.length })
                      }
                      ref="title"
                      type="text"
                      name="title"
                      className="form-control board-editor-input"
                      placeholder="제목을 입력하세요"
                    />
                  </td>
                </tr>
                <tr>
                  <td>이미지파일</td>
                  <td>
                    <input
                      className="form-control board-editor-input"
                      ref="file"
                      type="file"
                      name="image"
                      placeholder="jpg, jpeg, png 파일만 업로드가 가능합니다."
                      onChange={(e) =>
                        this.setState({ file: e.target.files[0] })
                      }
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="2">
                    글 내용(*) &nbsp;&nbsp;
                    {this.state.contentLength}
                    /2000
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <textarea
                      onChange={(e) =>
                        this.setState({ contentLength: e.target.value.length })
                      }
                      ref="content"
                      type="textarea"
                      name="content"
                      className="form-control board-editor-input"
                      rows="10"
                      placeholder="글 내용을 입력해주세요."
                    />
                  </td>
                </tr>
                <tr className="text-center">
                  <td colSpan="2">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.onPress();
                      }}
                      className="board-editor-form-submit-button"
                    >
                      작성완료
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        )}
      </div>
    );
  }
}
