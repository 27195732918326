import React from "react";
import {
  TREATMENT_FEES,
  MATERIALS_FEES,
  DRUG_FEES,
  CERTIFICATIONS_FEES,
} from "./Fee";

const FeeModal = () => (
  <div
    className="modal fade"
    id="modal-footer-1"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    style={{ color : "#212529", fontSize : ".8rem", fontWeight: 400 }}
  >
    <div className="modal-dialog modal-lg" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">비급여 진료비용 고지</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="table-responsive">
            <table
              className="fee-table table text-center table-striped table-bordered table-hover"
              cellSpacing="0"
              width="100%"
            >
              {makeTableHead("행위료", {
                containPrimaryCategory: true,
                containSecondaryCategory: true,
                containDistinction: true,
                containMinFee: true,
                containMaxFee: true,
                containIsIncludingMaterialFee: true,
                containIsIncludingDrugFee: true,
              })}
              {makeTableBody(TREATMENT_FEES, {
                containPrimaryCategory: true,
                containSecondaryCategory: true,
                containDistinction: true,
                containMinFee: true,
                containMaxFee: true,
                containIsIncludingMaterialFee: true,
                containIsIncludingDrugFee: true,
              })}
            </table>
          </div>
          <div className="table-responsive">
            <table
              className="fee-table table text-center table-striped table-bordered table-hover"
              cellSpacing="0"
              width="100%"
            >
              {makeTableHead("치료재료", {
                containPrimaryCategory: true,
                containSecondaryCategory: false,
                containDistinction: true,
                containMinFee: true,
                containMaxFee: true,
                containIsIncludingMaterialFee: false,
                containIsIncludingDrugFee: false,
              })}
              {makeTableBody(MATERIALS_FEES, {
                containPrimaryCategory: true,
                containSecondaryCategory: false,
                containDistinction: true,
                containMinFee: true,
                containMaxFee: true,
                containIsIncludingMaterialFee: false,
                containIsIncludingDrugFee: false,
              })}
            </table>
          </div>
          <div className="table-responsive">
            <table
              className="fee-table table text-center table-striped table-bordered table-hover"
              cellSpacing="0"
              width="100%"
            >
              {makeTableHead("약제비", {
                containPrimaryCategory: true,
                containSecondaryCategory: false,
                containDistinction: false,
                containMinFee: false,
                containMaxFee: false,
                containIsIncludingMaterialFee: false,
                containIsIncludingDrugFee: false,
              })}
              {makeTableBody(DRUG_FEES, {
                containPrimaryCategory: true,
                containSecondaryCategory: false,
                containDistinction: false,
                containMinFee: false,
                containMaxFee: false,
                containIsIncludingMaterialFee: false,
                containIsIncludingDrugFee: false,
              })}
            </table>
          </div>
          <div className="table-responsive">
            <table
              className="fee-table table text-center table-striped table-bordered table-hover"
              cellSpacing="0"
              width="100%"
            >
              {makeTableHead("제증명수수료", {
                containPrimaryCategory: true,
                containSecondaryCategory: false,
                containDistinction: true,
                containMinFee: false,
                containMaxFee: false,
                containIsIncludingMaterialFee: false,
                containIsIncludingDrugFee: false,
              })}
              {makeTableBody(CERTIFICATIONS_FEES, {
                containPrimaryCategory: true,
                containSecondaryCategory: false,
                containDistinction: true,
                containMinFee: false,
                containMaxFee: false,
                containIsIncludingMaterialFee: false,
                containIsIncludingDrugFee: false,
              })}
            </table>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary rounded-0"
            data-dismiss="modal"
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  </div>
);

const makeTableHead = (title, options = {}) => {
  const numberOfExceptions = Object.values(options).filter((value) => !value)
    .length;
  return (
    <thead>
      <tr>
        <th
          className="text-left font-weight-bold"
          colSpan={`${12 - numberOfExceptions}`}
        >
          {`${title}`}
        </th>
      </tr>
      <tr>
        {options.containPrimaryCategory && <th>중분류</th>}
        {options.containSecondaryCategory && <th>소분류</th>}
        <th>코드</th>
        <th>명칭</th>
        {options.containDistinction && <th>구분</th>}
        <th>비용</th>
        {options.containMinFee && <th>최저비용</th>}
        {options.containMaxFee && <th>최고비용</th>}
        {options.containIsIncludingMaterialFee && <th>치료 재료대 포함여부</th>}
        {options.containIsIncludingDrugFee && <th>약제비 포함여부</th>}
        <th>특이사항</th>
        <th>최종변경일</th>
      </tr>
    </thead>
  );
};

const makeTableBody = (fees = [], options = {}) => {
  const isEmpty = fees.length === 0;
  const numberOfExceptions = Object.values(options).length;
  return (
    <tbody>
      {isEmpty ? (
        <tr>
          <td colSpan={`${12 - numberOfExceptions}`}>빈 항목입니다.</td>
        </tr>
      ) : (
        fees.map((fee) => (
          <tr>
            {options.containPrimaryCategory && <td>{fee.primaryCategory}</td>}
            {options.containSecondaryCategory && (
              <td>{fee.secondaryCategory}</td>
            )}
            <td>{fee.code}</td>
            <td>{fee.name}</td>
            {options.containDistinction && <td>{fee.distinction}</td>}
            <td>{fee.fee ? parseInt(fee.fee, 10).toLocaleString('ko-KR') : ""}</td>
            {options.containMinFee && <td>{fee.minFee ? parseInt(fee.minFee, 10).toLocaleString('ko-KR') : ""}</td>}
            {options.containMaxFee && <td>{fee.maxFee ? parseInt(fee.maxFee, 10).toLocaleString('ko-KR') : ""}</td>}
            {options.containIsIncludingMaterialFee && (
              <td>{fee.isIncludingMaterialFee}</td>
            )}
            {options.containIsIncludingDrugFee && (
              <td>{fee.isIncludingDrugFee}</td>
            )}
            <td>{fee.significantReport}</td>
            <td>{fee.lastEditDate}</td>
          </tr>
        ))
      )}
    </tbody>
  );
};

export default FeeModal;
