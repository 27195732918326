import * as types from "../actions/ActionTypes";

const initialState = {
  contents: [],
};

function popup(state = initialState, action) {
  switch (action.type) {
    case types.POP_UP_FETCH_SUCCESS:
      return {
        contents: action.popup,
      };
    default:
      return {
        ...state,
      };
  }
}

export default popup;
